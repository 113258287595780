.favorite-container {
  .secondary-btn.favorite-button {
    svg path {
      fill: var(--hmf-label-base);
    }

    &:hover:not([aria-disabled='true']) {
      svg path {
        fill: var(--hmf-fill-primary-hover);
      }
    }

    & .selected path {
      fill: var(--hmf-label-primary-static);
    }
  }
}

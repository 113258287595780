.calia-react-footer-scope {
  .footer-mobile-accordion {
    background: var(--hmf-background-base);
    width: 100%;

    .accordion-item {
      border-bottom: 1px solid var(--hmf-border-base);

      h3 {
        color: var(--hmf-gray900);
      }
    }

    .accordion-header {
      background: none;
      border: none;
      cursor: pointer;
      gap: var(--hmf-s);
      height: 68px;
      width: 100%;
    }

    .accordion-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;

      &.open {
        max-height: 1000px;
        padding: 0 24px 16px 24px;
      }

      a {
        color: var(--hmf-gray900);

        &:hover {
          text-decoration: underline var(--button-static-bg-color);
          text-underline-offset: var(--hmf-xxxs);
        }
      }
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.my-store-button-new {
  color: var(--hmf-label-base);
  cursor: pointer;
  background-color: transparent;
  border: none;
  @include homefield.media-breakpoint-up('md') {
    height: 56px;
  }
  &:active {
    background: var(--hmf-fill-secondary-active);
    border-radius: var(--hmf-soft-radius);
  }
  &:hover {
    background-color: var(--hmf-fill-secondary-hover);
    border-radius: var(--hmf-soft-radius);
  }
  &:focus-visible {
    background-color: var(--hmf-fill-secondary-static);
    border: 1px dashed var(--hmf-border-focused);
    border-radius: var(--hmf-soft-radius);
    outline: none;
  }

  &.mobile-menu {
    width: 100%;
    height: auto;
    border-radius: 0;
    background-color: var(--hmf-fill-secondary-static-subtle);
    border-bottom: 1px solid var(--hmf-border-base);
    cursor: pointer;
    &:hover {
      background-color: var(--hmf-fill-secondary-hover-subtle);
    }
    &:focus-visible {
      outline: 1px dashed var(--hmf-border-focused);
      outline-offset: -4px;
      border: none;
      border-bottom: 1px solid var(--hmf-border-base);
    }
  }

  .store-name {
    text-transform: capitalize !important;
  }

  .store-hours {
    color: var(--hmf-primary600);
    &.store-closed {
      color: var(--hmf-red500);
    }
  }
}

.calia-react-header-scope.hmf-pl-theme {
  .my-store-button-new {
    .store-hours {
      @include homefield.media-breakpoint-up('m') {
        color: var(--hmf-gray50);
      }
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.quickview-caption-box {
  background-color: var(--hmf-fill-disabled);
  border-radius: 5px;
  height: 20px;
  margin-bottom: 8px;
  margin-right: 60%;
}

.quickview-promo-box {
  background-color: var(--hmf-fill-disabled);
  border-radius: 5px;
  height: 20px;
  margin-bottom: 8px;
  margin-right: 45%;
}

.quickview-image-box {
  animation: fading 1.5s infinite;
  background-color: var(--hmf-fill-disabled);
  height: 258px;
  margin-bottom: 10px;
}

.quickview-attribute-box {
  animation: fading 1.5s infinite;
  background-color: var(--hmf-fill-disabled);
  border-radius: 5px;
  height: 41px;
  margin-bottom: 10px;
}

.quickview-shipping-box {
  animation: fading 1.5s infinite;
  background-color: var(--hmf-fill-disabled);
  border-radius: 5px;
  height: 85px;
  margin-bottom: 20px;
}

.quickview-quantity-box {
  animation: fading 1.5s infinite;
  background-color: var(--hmf-fill-disabled);
  border-radius: 5px;
  height: 69px;
  margin-bottom: 20px;
}

.quickview-short-box {
  background-color: var(--hmf-fill-disabled);
  border-radius: 5px;
  height: 20px;
  margin-bottom: 8px;
  margin-right: 70%;
}

.quickview-description-box {
  animation: fading 1.5s infinite;
  background-color: var(--hmf-fill-disabled);
  border-radius: 5px;
  height: 40px;
  margin-bottom: 20px;
}

.quickview-addToCart-box {
  background-color: var(--hmf-fill-disabled);
  width: 100%;
  height: 44px;

  @include homefield.media-breakpoint-up('md') {
    height: 56px;
  }

  p {
    color: var(--hmf-label-secondary-subtle-static);
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-align: center;
    text-transform: uppercase;

    &.cart-text {
      padding: var(--hmf-m) 0;

      @include homefield.media-breakpoint-down('md') {
        padding: var(--hmf-xs) 0;
      }
    }
  }

  &.cancel {
    width: 70%;
  }
}

@keyframes fading {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.2;
  }
}

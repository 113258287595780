@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.mobile-nav-container {
  @keyframes slide-in {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0%);
    }
  }
  display: flex;
  min-height: 100vh;
  padding-bottom: 150px;

  position: absolute;
  width: 100%;
  z-index: 100;
  &__spacer {
    background-color: var(--mobile-nav-accent-background-color);
    border-bottom: var(--mobile-nav-border-spacer-styling);
    margin: 0;
  }

  &__my-account {
    border: none;
    width: 100%;
    background-color: var(--mobile-nav-accent-background-color);
    border-bottom: var(--mobile-nav-border-styling);
    color: var(--hmf-label-base);
    cursor: pointer;
    &:hover {
      background-color: var(--hmf-fill-secondary-active);
    }
    &:focus-visible {
      outline: 1px dashed var(--hmf-border-focused);
      outline-offset: -4px;
      border: none;
      border-bottom: 1px solid var(--hmf-border-base);
    }
    &:active {
      background-color: var(--hmf-gray200);
    }
  }

  &__nav-container {
    animation: slide-in 0.5s ease;
    -webkit-animation: slide-in 0.5s ease;
    background-color: var(--color-white);
    border-right: var(--mobile-nav-border-styling);
    border-top: var(--mobile-nav-border-styling);
    max-width: 500px;
    overflow-y: scroll;
    padding-bottom: 150px;
    width: 90%;
    z-index: 101;
    height: calc(100vh - 149px);
    @include homefield.media-breakpoint-up('m') {
      height: calc(100vh - 202px);
    }
  }

  &__sub-menu-container {
    background-color: var(--color-white);
    border-right: var(--mobile-nav-border-styling);
    border-top: var(--mobile-nav-border-styling);
    max-width: 500px;
    overflow-y: scroll;
    width: 90%;
    z-index: 101;
    height: calc(100vh - 149px);
    @include homefield.media-breakpoint-up('m') {
      height: calc(100vh - 202px);
    }
  }

  .sub-menu-body {
    flex-grow: 1;
    > .accordion-container {
      padding-bottom: 150px;
    }
  }
  &__nav {
    background-color: var(--color-white);
    &--list-item {
      border: none;
      background-color: var(--hmf-fill-secondary-static);
      border-bottom: var(--mobile-nav-border-styling);
      color: var(--nav-menu-font-color);
      cursor: pointer;
      &:focus-visible {
        outline: 1px dashed var(--hmf-border-focused);
        outline-offset: -4px;
      }
      &:hover {
        background-color: var(--hmf-fill-secondary-hover);
      }
      &:active {
        background-color: var(--hmf-fill-secondary-active);
      }
    }
  }

  &__shop-all {
    background-color: var(--hmf-fill-secondary-static);
    border-bottom: var(--mobile-nav-border-styling);
    &.sticky {
      position: sticky;
      top: 0;
      z-index: 10;
    }
    &--icon-wrapper {
      background-color: var(--hmf-fill-secondary-static-subtle);
      border: none;
      border-right: 1px solid var(--hmf-gray200);
      cursor: pointer;
      &:hover {
        background-color: var(--hmf-fill-secondary-hover-subtle);
      }
      &:focus-visible {
        outline: 1px dashed var(--hmf-border-focused);
        outline-offset: -4px;
      }
      &:active {
        background-color: var(--hmf-fill-secondary-active-subtle);
      }
    }

    &--shop-all-title {
      color: var(--nav-menu-font-color);
      &:hover {
        .mobile-nav-container__shop-all--shop-all-text {
          color: var(--hmf-label-secondary-hover);
          text-decoration: underline;
          text-decoration-color: var(--hmf-label-secondary-hover);
          text-underline-offset: 3px;
        }
      }
      &:focus-visible {
        outline: 1px dashed var(--hmf-border-focused);
        outline-offset: -4px;
        .mobile-nav-container__shop-all--shop-all-text {
          color: var(--hmf-label-secondary-static);
          text-decoration: none;
        }
      }
      &:active {
        border-bottom: 2px solid var(--secondary-color);
        margin-bottom: -2px !important;
        .mobile-nav-container__shop-all--shop-all-text {
          color: var(--hmf-label-secondary-active);
        }
      }
    }
  }

  .close-button {
    background-color: var(--hmf-fill-inverse);
    color: var(--color-white);
    border: none;
    cursor: pointer;
    height: 56px;
    width: 51px;
    z-index: 101;
    &:focus {
      border: 1px dashed var(--color-white);
      box-sizing: border-box;
      outline: none;
    }
    &:hover {
      opacity: 0.75;
    }
  }

  .clearance,
  .clearance:visited {
    color: var(--hmf-red900);
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.trending-searches-container {
  width: 100%;
  border-bottom: 1px solid var(--header-border-color);
}

.trending-searches {
  overflow-x: auto;
  &:empty {
    display: none;
  }
  .headline {
    color: var(--header-trending-search-heading);
    white-space: nowrap;
  }

  .chip-container {
    gap: var(--hmf-xxs);
    width: 100%;
    white-space: nowrap;

    &::after {
      content: '';
      display: block;
      width: var(--hmf-xs);
      flex-shrink: 0;
    }

    @include homefield.media-breakpoint-up('md') {
      gap: var(--hmf-s);
    }

    @include homefield.media-breakpoint-up('xlg') {
      gap: var(--hmf-m);
    }

    @include homefield.media-breakpoint-up('lg') {
      &::after {
        content: none;
        display: none;
      }
    }
  }
}

/*! purgecss start ignore */
@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.hmf-modal.store-selection-modal {
  z-index: 110;
  .hmf-modal-container {
    flex-direction: column;
    overflow-y: scroll !important;
    width: 100% !important;
    @include homefield.media-breakpoint-up('lg') {
      width: 30% !important;
    }
    @include homefield.media-breakpoint-up('xlg') {
      width: 40% !important;
    }

    .hmf-modal-container-wrapper {
      .hmf-close-primary {
        background-color: inherit;
      }
    }

    .store-selection-modal-container {
      width: 100%;
      .store-selection-modal-header {
        background-color: var(--hmf-gray100);
        border: 1px solid var(--hmf-gray200);
        box-sizing: border-box;
        height: 53px;
      }
      .store-selection-modal-body {
        .store-selection-modal-input-container {
          border-bottom: 1px solid var(--hmf-gray200);
          .store-selection-modal-search {
            gap: var(--hmf-xxs);
            .store-selection-modal-search-input {
              flex-grow: 3;
              &::placeholder {
                user-select: none;
              }
            }
            .store-selection-modal-search-button {
              border-radius: 2px;
              flex-grow: 2;
              height: 44px;
              min-width: 125px;
            }
          }
        }

        .store-selection-modal-list-error {
          color: var(--hmf-red700);
        }
      }
    }
  }
}
/*! purgecss end ignore */

.store-selection-model-nearby-msg {
  border-bottom: 1px solid var(--hmf-gray200);
  color: var(--hmf-gray700);
}

.store-selection-modal-store-details-container {
  border-bottom: 1px solid var(--hmf-gray200);
}

.store-selection-modal-search-options {
  justify-content: space-between;
}

.store-selection-modal-location-icon {
  height: 1.2em;
  width: 1.2em;
}

.store-selection-modal-current-selected-store {
  background-color: var(--hmf-gray100);
}

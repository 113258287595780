.quickview-swatch-container {
  margin-right: 0;
}

.quickview-outline {
  padding: 2px;
}

.quickview-outline:active {
  border: 1px dashed var(--theme-focus-outline-color);
  padding: 1px;
}

.quickview-swatch {
  cursor: pointer;
  height: 85px;
  width: 85px;
  background: transparent;
  border: 2px solid transparent;
}

.quickview-swatch img {
  background-color: var(--hmf-background-base);
  border: 1px solid var(--hmf-gray50);
  border-radius: 2px;
  cursor: pointer;
  height: 80px;
  max-width: 98%;
  padding: 4px;
  width: 80px;
}

.quickview-swatch:hover {
  border: 2px solid var(--hmf-tertiary700);
  border-radius: 2px;
}

.quickview-selected-swatch {
  border: 2px solid var(--hmf-tertiary700);
  border-radius: 2px;

  img {
    border: none;
  }
}

.quickview-disabled-swatch {
  &::before {
    border-top: 2px solid var(--hmf-border-secondary-subtle-active);
    content: '';
    outline: none;
    position: absolute;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    width: 82px;
  }

  &.quickview-selected-swatch::before {
    outline: 2px solid var(--hmf-primary50);
  }
}

.price {
  align-self: center;
  color: var(--hmf-primary900);
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin: 0px 4px;
}

.selected-price {
  font-weight: bold;
}

.clearence {
  color: var(--hmf-secondary900);
}

.visibilty-switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: var(--hmf-l);
  }

  .switch input {
    position: absolute;
    opacity: 0;
    width: 51px;
    height: var(--hmf-l);
    z-index: 2;
    cursor: pointer;
  }

  .slider {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--hmf-border-base-prominent);
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid var(--hmf-border-base-prominent);
  }

  .slider:before {
    position: absolute;
    content:'';
    width: 26px;
    height: 26px;
    background-color: var(--hmf-fill-base);
    border-radius: 50%;
    left: 1px;
    top: 1px;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid var(--hmf-border-base-prominent);
  }

  .active {
    background-color: var(--hmf-fill-primary-static);
    border: 1px solid var(--hmf-fill-primary-static);
  }

  .slider.active {
    box-shadow: 0 0 1px var(--hmf-fill-primary-static);
  }

  .active.slider:before  {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    border: 1px solid var(--hmf-fill-primary-static);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: var(--hmf-l);
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.store-details-container {
  justify-content: space-between;
  .store-details-button {
    border-radius: 2px;
    flex-grow: 13;
    height: 44px;
    min-width: 125px;
  }
  @media screen and (max-width: 767px) {
    .store-details-button {
      border-radius: 2px;
      flex-grow: 10;
      height: 32px;
      min-width: 125px;
    }
  }
}

.store-details-store-hours-day {
  display: inline-block;
  text-align: left;
  width: 45%;
}

.store-details-store-hours-time {
  display: inline-block;
  padding-left: 10px;
  text-align: left;
  width: 55%;
}

.store-details-show-toggle-container {
  flex-grow: 7;
}
.store-details-current-store-label {
  color: var(--hmf-primary600);
}

.store-details-name-dist-container {
  justify-content: space-between;
}
.store-details-address {
  color: var(--hmf-gray700);
  text-transform: capitalize;
}

.store-details-check-svg {
  path {
    fill: var(--store-details-check-icon-color);
  }
}

.store-details-cancel-svg {
  path {
    fill: var(--hmf-red500);
  }
}

.store-details-store-pickup {
  color: var(--hmf-gray700);
}

.store-details-status-after_hours {
  color: var(--hmf-red500);
}

.store-details-status-when_open {
  color: var(--hmf-primary500);
}

.store-details-chevron {
  pointer-events: none;
}

.store-details-view-more {
  color: var(--hmf-gray700) !important;
}

.store-details-only-few-left-message {
  color: var(--hmf-red900) !important;
}

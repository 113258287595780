/*! purgecss start ignore */
@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.modal-wrapper {
  .modal-content-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    color: var(--color-white);
    height: auto;
    max-height: 80vh;
    width: 600px;
  }
  .modal-link {
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    flex: 1;
    width: 100%;
    z-index: 1001;
    a {
      color: inherit;
    }
    &:focus-visible,
    &:focus {
      outline-offset: -8px;
      outline: 1px dashed var(--hmf-border-inverse-focused);
    }
  }
  .disclaimer {
    box-sizing: border-box;
    width: 100%;
    .details-link {
      color: inherit;
      &:focus-visible,
      &:focus {
        outline: 1px dashed var(--hmf-border-inverse-focused);
      }
    }
  }
  .close-button {
    cursor: pointer;
    position: absolute;
    border: none;
    right: 0;
    top: 0;
    &:hover {
      opacity: 0.75;
    }
    &:focus-visible,
    &:focus {
      outline: 1px dashed var(--hmf-border-inverse-focused);
    }
  }
}

/*! purgecss end ignore */

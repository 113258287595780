.calia-react-header-scope.hmf-gg-theme,
.calia-react-footer-scope.hmf-gg-theme {
  @extend .calia-react-header-scope;

  // OVERRIDES
  --header-icon-hover-color: var(--hmf-fill-secondary-hover);
  --header-icon-focused-color: var(--hmf-fill-secondary-static);
  --header-icon-border-focused: var(--hmf-border-focused);
  --header-icon-active-color: var(--hmf-fill-secondary-active);
  --header-logo-container-justify-content: flex-start;
  --focus-color: var(--hmf-gray200);
  --search-bar-focus-border-color: var(--hmf-primary800);
  --search-bar-icon-color: var(--primary-color);
  --font-color-primary: var(--hmf-secondary600);
  --secondary-color-light: var(--hmf-secondary400);
  --tertiary-accent-color: var(--hmf-secondary500);
  --drop-down-menu-button-text-color: var(--hmf-secondary600);
  --ticker-color: var(--hmf-primary900);
  --header-trending-search-heading: var(--hmf-primary800);

  // TYPOGRAPHY
  --font-family-primary: 'Hind Siliguri';
  --font-family-secondary: 'Vitesse';
  --primary-font-family: 'Hind Siliguri';
  --primary-font: 'Hind Siliguri';
  --special-case-font-weight: 400;
  --special-case-font: 'Vitesse';

  // PRIMARY COLORS
  --primary-color: var(--hmf-gray900);
  --secondary-color: var(--hmf-secondary600);
  --tertiary-color: var(--hmf-secondary400);
  --theme-focus-outline-color: var(--hmf-tertiary600);
  --theme-primary-color: var(--hmf-primary900);

  // COMPONENT VARS
  --accordion-flag-background-color: var(--hmf-secondary600);
  --aos-header-current-offers-color: var(--hmf-gray50);
  --columns-background: var(--hmf-background-base);
  --desktop-svg-fill-color: var(--primary-color);

  // NAVIGATION
  --desktop-nav-column-category-hover-border: 2px solid var(--hmf-secondary600);
  --desktop-nav-link-color: var(--hmf-gray900);
  --desktop-nav-link-hover-border-color: var(--hmf-secondary600);
  --desktop-nav-link-hover-color: var(--hmf-gray900);
  --desktop-nav-title-border-color: var(--hmf-secondary600);
  --nav-menu-font-color: var(--hmf-gray900);
  --nav-menu-hover-color: var(--secondary-color);

  // TOP NAVBAR
  --top-navbar-background-color: var(--hmf-primary300);
  --top-navbar-left-line-height: var(--header-line-height-xs);
  --top-navbar-left-text-transform: none;
  --top-navbar-links-hover-text-decoration-color: var(--hmf-secondary500);
  --top-navbar-right-font-weight: normal;

  // CART
  --oval-count-background-color: var(--hmf-secondary600);

  // TICKER
  --ticker-background: var(--color-white);
  --ticker-font-family: 'Hind Siliguri';
  --ticker-link-font-family: 'Hind Siliguri';

  // SCORE MORE
  --score-more-background-color: var(--hmf-primary800);

  // QUICKVIEW QUANTITY FONT STYLES
  --quick-view-close-button-color: var(--hmf-fill-inverse);
  --quick-view-final-price-color: var(--hmf-gray900);
  --quick-view-quantity-items-border-radius: 24.5px;
  --quick-view-quantity-items-height: 49px;
  --quick-view-shipping-border-bottom-color: var(--hmf-secondary600);
  --quick-view-slider-dots: var(--hmf-primary900);
  --quick-view-unlisted-price-text-transform: uppercase;

  // FOOTER
  --footer-details-background: var(--hmf-background-base-subtle);
  --footer-details-color: var(--hmf-secondary900);

  // EMAIL SIGNUP
  --footer-email-signup-signup-success-color: var(--hmf-gray50);

  // STORE PICKUP
  --store-pickup-selected-store-color: var(--secondary-color);

  // LINK COMPONENT
  --link-component-font-size: var(--body-font-size-s);
  --link-component-font-weight: normal;
  --link-component-letter-spacing: 0.5px;
  --link-component-line-height: 20px;

  --link-component-hover-focus-active-bottom-border: var(--hmf-secondary500);
  --link-component-static-bottom-border: var(--hmf-secondary900);
  --link-component-visited-bottom-border: var(--hmf-primary700);

  --link-component-alternate-font-color: var(--hmf-primary400);

  --link-component-inline-font-size: var(--body-font-size-s);
  --link-component-inline-line-height: 20px;

  --link-component-inline-active-visited-font-color: var(--hmf-primary400);
  --link-component-inline-hover-focus-font-color: var(--hmf-secondary600);

  // GG SPECIFIC REACT BUTTON VALUES
  --button-active-bg-color: var(--hmf-secondary500);
  --button-color: var(--hmf-primary50);
  --button-focus-outline: 2px dashed var(--hmf-tertiary600) !important;
  --button-font-weight: bold;
  --button-hover-bg-color: var(--hmf-secondary700);
  --button-label-underline-color: var(--hmf-secondary600);
  --button-static-bg-color: var(--hmf-secondary600);

  // GG SPECIFIC - BUTTON COMPONENT - COMMON STYLES
  --button-font-family: 'Hind Siliguri';
  --button-font-size: 16px;
  --button-font-style: normal;
  --button-height: 45px;
  --button-letter-spacing: 1px;
  --button-line-height: 16px;
  --button-text-transform: uppercase;
  --button-width: 100%;

  // GG SPECIFIC - BUTTON COMPONENT - PRIMARY

  // PRIMARY STATIC
  --button-primary-static-bg-color: var(--hmf-secondary800);
  --button-primary-static-border: none;
  --button-primary-static-font-color: var(--color-white);
  --button-primary-static-radius: 0px;

  // PRIMARY HOVER
  --button-primary-hover-bg-color: var(--hmf-secondary600);
  --button-primary-hover-border: none;
  --button-primary-hover-font-color: var(--color-white);
  --button-primary-hover-radius: 0px;

  // PRIMARY ACTIVE
  --button-primary-active-bg-color: var(--hmf-secondary900);
  --button-primary-active-border: none;
  --button-primary-active-font-color: var(--color-white);
  --button-primary-active-radius: 0px;

  // PRIMARY INACTIVE
  --button-primary-inactive-bg-color: var(--hmf-primary100);
  --button-primary-inactive-border: none;
  --button-primary-inactive-font-color: var(--hmf-primary400);
  --button-primary-inactive-radius: 0px;

  // PRIMARY FOCUSED
  --button-primary-focus-bg-color: var(--hmf-secondary800);
  --button-primary-focus-border: none;
  --button-primary-focus-font-color: var(--color-white);
  --button-primary-focus-outline: 1px dashed var(--hmf-tertiary600);
  --button-primary-focus-radius: 0px;

  // GG SPECIFIC - BUTTON COMPONENT - SECONDARY

  // SECONDARY STATIC
  --button-secondary-static-bg-color: var(--color-white);
  --button-secondary-static-border: 2px solid var(--hmf-primary800);
  --button-secondary-static-font-color: var(--hmf-primary800);
  --button-secondary-static-radius: 0px;

  // SECONDARY HOVER
  --button-secondary-hover-bg-color: var(--hmf-primary100);
  --button-secondary-hover-border: 2px solid var(--hmf-primary800);
  --button-secondary-hover-font-color: var(--hmf-primary800);
  --button-secondary-hover-radius: 0px;

  // SECONDARY ACTIVE
  --button-secondary-active-bg-color: var(--hmf-primary100);
  --button-secondary-active-border: 2px solid var(--hmf-primary300);
  --button-secondary-active-font-color: var(--hmf-primary800);
  --button-secondary-active-radius: 0px;

  // SECONDARY INACTIVE
  --button-secondary-inactive-bg-color: var(--color-white);
  --button-secondary-inactive-border: 2px solid var(--hmf-primary100);
  --button-secondary-inactive-font-color: var(--hmf-primary400);
  --button-secondary-inactive-radius: 0px;

  // SECONDARY FOCUSED
  --button-secondary-focus-bg-color: var(--color-white);
  --button-secondary-focus-border: 2px solid var(--hmf-primary800);
  --button-secondary-focus-font-color: var(--hmf-primary800);
  --button-secondary-focus-outline: 1px dashed var(--hmf-tertiary600);
  --button-secondary-focus-radius: 0px;

  // GG SPECIFIC - BUTTON COMPONENT - TERTIARY

  // TERTIARY STATIC
  --button-tertiary-static-bg-color: var(--color-white);
  --button-tertiary-static-border: none;
  --button-tertiary-static-font-color: var(--hmf-primary800);
  --button-tertiary-static-radius: 0px;

  // TERTIARY HOVER
  --button-tertiary-hover-bg-color: var(--color-white);
  --button-tertiary-hover-border: none;
  --button-tertiary-hover-font-color: var(--hmf-primary800);
  --button-tertiary-hover-label-underline: 1px solid var(--hmf-secondary800);
  --button-tertiary-hover-radius: 0px;

  // TERTIARY ACTIVE
  --button-tertiary-active-bg-color: var(--color-white);
  --button-tertiary-active-border: none;
  --button-tertiary-active-font-color: var(--hmf-primary800);
  --button-tertiary-active-label-underline: 2px solid var(--hmf-secondary800);
  --button-tertiary-active-radius: 0px;

  // TERTIARY INACTIVE
  --button-tertiary-inactive-bg-color: var(--color-white);
  --button-tertiary-inactive-border: none;
  --button-tertiary-inactive-font-color: var(--hmf-primary400);
  --button-tertiary-inactive-radius: 0px;

  // TERTIARY FOCUSED
  --button-tertiary-focus-bg-color: var(--color-white);
  --button-tertiary-focus-border: none;
  --button-tertiary-focus-font-color: var(--hmf-primary800);
  --button-tertiary-focus-outline: 1px dashed var(--hmf-tertiary600);
  --button-tertiary-focus-radius: 0px;

  // AOS
  --aos-current-offers-bg-color: var(--hmf-secondary600);
}

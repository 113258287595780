.footerMessaging-container {
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;
  @media screen and (min-width: 992px) {
    align-items: center;
    border-bottom: 1px solid var(--color-white);
    flex-direction: row;
    justify-content: space-between;
  }

  .pl-footer-full-logo {
    display: flex;
    width: 275px;

    path {
      fill: var(--hmf-primary50);
    }

    .pl-footer-logo {
      @media (max-width: 576px) {
        width: var(--footer-logo-width);
      }
    }
  }

  .pl-messaging-icon {
    height: 20px;
    width: 20px;

    path {
      fill: var(--hmf-primary50);
    }
  }

  .--typo {
    height: 30px;
    width: 230px;
  }
  .--icon {
    margin-right: 14px;
    width: 35px;
  }

  &__content-wrapper {
    align-items: baseline;
    border-bottom: 1px solid var(--tertiary-accent-color);
    svg {
      width: 60px;
    }
    &:last-child {
      border: none;
    }
    @media screen and (min-width: 992px) {
      border-bottom: none;
      border-right: 1px solid var(--color-white);
    }
  }

  &__content-container {
    flex-direction: column;
    @media screen and (min-width: 992px) {
      flex-direction: row;
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.utility-links-button {
  background: var(--hmf-gray100);
  border: var(--hmf-gray100);
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  width: 100%;

  @include homefield.media-breakpoint-up('lg') {
    background: none;
  }

  &:hover {
    background: var(--hmf-gray100);
  }

  &:focus {
    outline: 1px dashed var(--hmf-gray600);
    outline-offset: 4px;
  }
}

.utility-links-container {
  background: var(--hmf-gray100);
  width: 100%;
  @include homefield.media-breakpoint-up('lg') {
    background: none;
    // 330 (L0 width in lg) * 0.9 = 297
    min-width: 297px;
    width: 297px;
    @media only screen and (min-height: 1050px) {
      bottom: 0;
      left: 20px;
      padding-bottom: var(--hmf-m);
      position: absolute;
    }
  }

  @include homefield.media-breakpoint-up('xlg') {
    // 400 (L0 width in xlg) * 0.9 = 330.9
    min-width: 360px;
    width: 360px;
  }

  .utility-link-text {
    color: var(--hmf-gray900);
  }
}

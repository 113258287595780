.MobileNavBar-container {
  background-color: var(--hmf-fill-secondary-static);
  &__text-container {
    align-items: center;
    border-bottom: 1px solid var(--hmf-gray200);
    display: flex;
    max-height: 56px;
    min-height: 56px;
    &:hover {
      background-color: var(--hmf-fill-secondary-hover);
    }
    &:active {
      background-color: var(--hmf-fill-secondary-active);
    }
    &:focus-visible {
      border: 1px dashed var(--hmf-border-focused);
      outline: none;
    }

    &--links {
      color: var(--nav-menu-font-color);
      text-decoration: none;
      width: 100%;
      &:focus {
        box-sizing: border-box;
        outline: 1px dashed var(--focus-color);
      }
    }
    &--link-title {
      margin: 0;
      &:hover {
        text-decoration: underline;
        text-decoration-color: var(--secondary-color);
        text-decoration-thickness: 2px;
      }
    }
    &--description {
      color: var(--hmf-gray700);
      margin: 0;
      &:hover {
        text-decoration: none !important;
      }
    }
    &--icon {
      height: 24px;
      width: 24px;
    }
  }
}

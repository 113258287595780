@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

$search-results-border: 1px solid var(--hmf-gray200);

.dsg-search-results {
  border-bottom: $search-results-border;
  border-top: $search-results-border;
  @include homefield.media-breakpoint-up('m') {
    background-color: var(--color-white);
    border: $search-results-border;
    flex: 1 1 45%;
    min-width: 45%;
  }
  a {
    @include homefield.media-breakpoint-up('m') {
      flex: 0 0 50%;
      flex-direction: column;
    }
    align-items: center;
    display: -ms-flexbox;
    overflow: hidden;
    padding: 0;
    &:focus-visible {
      outline: 1px dashed var(--focus-color);
    }

    .dsg-search-thumbnail {
      display: none !important;
      @include homefield.media-breakpoint-up('m') {
        display: block !important;
      }
      img {
        height: 110px;
        margin: auto;
        width: 110px;
      }
    }

    .product-information {
      .product-name {
        border: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--search-results-font-color);
        overflow: hidden;
        text-overflow: ellipsis;
        @include homefield.media-breakpoint-up('m') {
          border: none;
        }
        height: auto;
      }

      .dsg-search-price {
        display: none !important;
        @include homefield.media-breakpoint-up('m') {
          display: block !important;
        }
        color: var(--nav-menu-font-color);
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
          margin-bottom: 2px;
          margin-right: 8px;
        }

        .offer-price {
          display: none !important;
          @include homefield.media-breakpoint-up('m') {
            display: block !important;
            flex: 0 0 50%;
            flex-direction: column;
          }
          color: var(--hmf-red900);
        }
      }

      .product-with-discount {
        display: none !important;
        @include homefield.media-breakpoint-up('m') {
          display: block !important;
          flex: 0 0 50%;
          flex-direction: column;
        }

        .dsg-search-discounted-price {
          color: var(--hmf-red900);
        }

        .max-offer-sigle {
          padding-bottom: 4px;
          padding-right: 8px;
          width: 100%;
        }

        .min-offer-list {
          display: inline-flex;
          flex-direction: column;
          padding-bottom: 4px;
          padding-right: 8px;
        }

        .dsg-search-original-price {
          color: var(--color-black);
          display: inline-flex;
        }
      }
    }
  }
}

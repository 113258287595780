@use '~@dsg-tech/homefield-styles/src/lib/homefield.scss' as homefield;

.calia-react-header-scope {
  .quickview,
  .quickview-container {
    .hmf-modal-container {
      background-color: var(--hmf-background-base);
      font-family: var(--font-family-primary);
      height: 100%;
      overflow: hidden;
    }

    .product-title {
      max-width: 90%;

      p {
        text-transform: capitalize !important;
      }
    }

    .quickview-error {
      color: var(--hmf-label-negative);
      text-align: center;
    }

    .quickview-price {
      .final-price {
        color: var(--quick-view-final-price-color) !important;
        font-family: var(--font-family-primary);
        letter-spacing: 0.5px;
        line-height: 24px;
        margin: 0;
        padding-right: 8px;
      }

      .unlisted-price {
        margin: 0;
        padding-right: 8px;
        text-transform: var(--quick-view-unlisted-price-text-transform);
      }

      @include homefield.media-breakpoint-down('md') {
        left: -31px;
      }

      .priceInfo {
        margin-right: 13px;
      }

      &.center-price {
        margin: 0 auto;
      }
    }

    .placeholder {
      background-color: var(--hmf-background-inverse);
      height: 20px;
    }

    .placeholder.medium {
      height: 24px;
      margin-bottom: 20px;
    }

    .placeholder.small {
      height: 18px;
      margin-bottom: 10px;
    }

    .placeholder.large {
      height: 234px;
    }

    .lowStock {
      background-color: var(--hmf-fill-negative);
      height: 20px;
      width: 114px;

      p {
        color: var(--hmf-fill-base);
        margin: 0px;
        text-align: center;
      }
    }

    .separator {
      border-top: 1px solid var(--hmf-border-disabled-subtle);
    }

    .caption {
      &.attribute-error {
        margin-bottom: 10px;
      }
    }

    .attribute-error-message {
      color: var(--hmf-label-negative);
      letter-spacing: 1.5px !important;
      margin: 0;
      margin-bottom: 8px;
      margin-top: 8px;

      svg {
        transform: translateY(3px);
      }
    }

    .dsg-react-hyperlink.product-details {
      border-bottom: none;
      color: var(--quick-view-product-details-font-color);
      margin: 0 auto;
      padding-top: var(--hmf-s);
      text-decoration-line: underline;
      display: block;
      text-align: center;

      &:hover {
        border-bottom: none;
        text-decoration-color: var(--secondary-color);
      }
    }
    .alert-banner {
      .title-text {
        line-height: var(--header-line-height-l);
      }

      .alert-icon {
        width: var(--hmf-m);
        height: var(--hmf-m);
      }
    }

    .scroll-quickView-content {
      overflow: hidden;
      overflow-y: scroll;

      .dsg-react-hyperlink.product-details {
        display: block;
        text-align: center;
      }
    }

    .quickView-content-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: var(--hmf-background-base);

      &.separator {
        box-shadow: 0px -2px 8px -2px rgba(26, 26, 26, 0.2);
      }
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.search-forward-arrow {
  margin: -2px;
}

.search-icon {
  margin: 0 -2px -2px -2px;
}

.close-button-icon {
  display: block;
  margin: 8px -10px -5px -10px;
  path {
    fill: var(--hmf-gray50);
  }
}

.search-bar-container {
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
  width: 100%;
  @include homefield.media-breakpoint-up('xlg') {
    width: unset;
    flex-grow: 1;
  }

  .dsg-search {
    background-color: var(--search-bar-background-color);
    border: none;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    height: 44px;
    justify-content: center;
    margin: 0 auto;
    // padding added to offset 1px increase in border thickness when focused
    padding: 1px;
    position: relative;
    @include homefield.media-breakpoint-up('md') {
      height: 52px;
      position: relative;
    }
    border: 1px solid var(--search-bar-border-color);
    &:hover {
      border: 1px solid var(--search-bar-hover-border-color);
    }
    &:focus-within {
      border-color: var(--search-bar-focus-border-color);
      border-width: 2px;
      padding: 0px;
    }

    .dsg-clear-container-old {
      align-items: center;
      display: flex;
      .dsg-forward-logo {
        -webkit-appearance: none;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        color: var(--primary-color);
        cursor: pointer;
        margin-right: 10px;
        padding: 10px;
        &:focus-visible {
          outline: 1px dashed var(--focus-color);
        }
        svg path {
          fill: var(--search-bar-icon-color);
        }
      }

      .dsg-clear-button {
        color: var(--search-bar-icon-color);
        cursor: pointer;
        font-weight: 400 !important;
        text-decoration: underline;
        text-underline-offset: 1px;
        &:focus-visible {
          outline: 1px dashed var(--focus-color);
          outline-offset: 5px;
        }
      }
      .clear-forward-divider {
        background-color: var(--hmf-gray400);
        height: 30px;
        width: 1px;
      }
    }

    .search-forward-logo,
    .search-close-sayt-panel-icon {
      -webkit-appearance: none;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      color: var(--primary-color);
      cursor: pointer;
      &:focus-visible {
        outline: 1px dashed var(--focus-color);
      }
      svg path {
        fill: var(--primary-color);
      }
    }

    .dsg-search-input {
      border: 0;
      color: green;
      flex: 0 0 57%;
      height: 1.2rem;
      padding-left: 10px;
      padding-top: 9px;
      width: 100%;
    }

    input:hover ~ strong {
      display: none;
      @include homefield.media-breakpoint-up('md') {
        display: inline-block;
      }
    }
    input:focus {
      outline: none;
    }

    &-link {
      color: black;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 16px;
      text-decoration: none;
    }

    &-input {
      flex: 0 0 20%;
      height: 100%;
      width: 100%;
    }

    &-results-box {
      background-color: var(--search-results-box-background-color);
      border: none;
      display: flex;
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
      flex-direction: column;
      height: calc(100vh - 148px);
      left: 0;
      overflow-y: scroll;
      position: absolute;
      right: 0;
      top: 51px;
      z-index: 110;
      margin: 0 -18px;
      padding-bottom: 150px;
      @include homefield.media-breakpoint-up('md') {
        border: 1px solid var(--hmf-gray100);
        border-radius: 0px 0px 20px 20px;
        height: auto;
        overflow-y: unset;
        overflow: hidden;
        top: 53px;
        width: 100%;
        margin: unset;
        padding-bottom: 0;
      }
      .close-button-wrapper {
        position: sticky;
        top: 0;
        z-index: 110;
        .close-button {
          align-items: center;
          background-color: var(--close-icon-background-color);
          color: var(--color-white);
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 56px;
          position: absolute;
          right: 0px;
          top: 0px;
          width: 51px;
          z-index: 110;
          &__icon {
            height: 14px;
            width: 14px;
          }
          &:focus-visible {
            border: 1px dashed var(--color-white);
            box-sizing: border-box;
            outline: none;
          }
          &:hover {
            opacity: 0.75;
          }
        }
      }

      .dsg-search-suggestion-result-container {
        align-items: center;
        background-color: var(--color-white);
        border-bottom: 1px solid var(--hmf-gray200);
        display: flex;
        height: 56px;
        position: relative;
        width: 100%;
        &:hover {
          @media screen and (min-width: 992px) {
            background: var(--search-results-box-background-color);
            font-weight: bold;
            &:before {
              background-color: var(--secondary-color);
              content: '';
              height: 56px;
              left: 0;
              position: absolute;
              top: 0;
              width: 8px;
            }
          }
          .dsg-search-suggestion-result {
            color: var(--search-results-font-color);
            font-weight: bold;
            .dsg-search-suggestion-keyword {
              color: var(--search-results-font-color);
              font-weight: inherit;
            }
          }
        }

        &:focus-within {
          outline: 1px dashed var(--focus-color);
          outline-offset: -5px;
        }

        .dsg-search-suggestion-result {
          background-color: transparent;
          border: none;
          cursor: pointer;
          display: block;
          outline: none;
          padding-left: 15px;
          padding-right: 15px;
          width: 100%;
          .dsg-search-suggestion-keyword {
            font-weight: 400;
          }
        }
        .dsg-search-suggestion-arrow-button {
          background: none;
          border: none;
          cursor: pointer;
          float: right;
          padding: 16px 20px 12px 11.25px;
          @include homefield.media-breakpoint-up('md') {
            padding: 12px 20px 12px 11.25px;
          }
          &:hover {
            svg {
              fill: var(--secondary-color); //#bb5811;
              &.history-icon {
                fill: var(--color-black);
              }
            }
          }
        }
        svg {
          fill: var(--color-black);
          transform: rotate(45deg);
          &.history-icon {
            height: auto;
            margin-right: 8px;
            width: auto;
            &:hover {
              fill: var(--color-black);
            }
          }
          &:hover {
            fill: var(--secondary-color);
          }
        }
      }

      h1 {
        @include homefield.font('s', 'label');
        border-bottom: 1px solid var(--hmf-gray200);
        color: var(--search-results-header-color);
        margin: 0;
        padding: 30px 0 13px 12px;
        text-transform: uppercase;
        &.recent-search-heading {
          padding: 12px 0 13px 12px;
        }
      }

      .dsg-search-result-products {
        margin-left: 0;
        @include homefield.media-breakpoint-up('md') {
          display: flex;
        }

        .dsg-search-suggested-container {
          align-items: flex-start;
          flex-direction: column;
          margin: 0 0 8px;
          width: -webkit-fill-available;
          @include homefield.media-breakpoint-up('xlg') {
            border-right: 1px solid var(--hmf-gray200);
            display: flex;
            flex: 1 0 50%;
            max-width: 50%;
          }
          > div {
            width: 100%;
          }
          .dsg-search-suggested-categories,
          .dsg-search-suggested-brands,
          .dsg-search-suggested-products {
            .dsg-search-suggestion-result-container {
              background: var(--search-results-box-background-color);
            }
          }

          .dsg-search-suggested-keywords {
            .dsg-search-suggestion-result {
              font-weight: bold;
              white-space: nowrap;
              a {
              }
            }

            .top-suggestions {
              background-color: var(--color-white);
              border-bottom: 1px solid var(--hmf-gray200);
              color: var(--search-results-font-color);
              cursor: default;
              height: 56px;
              &:hover {
                color: var(--search-results-font-color);
              }
            }
          }
        }
        .dsg-search-products-container {
          border-left: 1px solid var(--hmf-gray300);
          width: 100%;
          &:focus-visible {
            outline: 1px dashed var(--focus-color);
            outline-offset: -10px;
          }
        }
        .dsg-search-top-products {
          color: var(--search-results-header-color);
          margin: 0;
          text-align: left;
          text-transform: uppercase;
        }
      }

      .dsg-search-products {
        @include homefield.media-breakpoint-up('md') {
          display: flex;
          flex: 0 0 50%;
          flex-wrap: wrap;
          padding: 0 12px;
        }
      }
    }

    .search-result-row {
      margin: 0 0 8px;
      padding: 16px 0;
      display: flex;
      @include homefield.media-breakpoint-up('md') {
        display: block;
      }
    }

    &-price {
      color: var(--hmf-primary900);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 18px;
    }

    &-suggestion {
      &-result {
        color: var(--search-results-font-color);
        text-decoration: none;
      }
    }

    &-view-all-results {
      text-align: center;
      padding: 48px 0 32px 0;
      &__link {
        color: var(--nav-menu-font-color);
        margin-left: auto;
        margin-right: auto;
        min-width: 50%;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: fit-content;

        &:hover {
          color: var(--nav-menu-font-color);
          text-decoration: solid var(--secondary-color) underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 4px;
        }
        &:active {
          text-decoration-thickness: 4px;
        }
        &:focus-visible {
          outline: 1px dashed var(--focus-color);
          outline-offset: 5px;
        }
      }
    }
    .search-box {
      align-items: center;
      display: flex;
      flex: 0 0 20%;
      float: left;
      height: 50%;
      input {
        height: 100%;
        width: 100%;
      }

      input.search-input {
        border: 0px solid;
      }
    }
  }

  .header-sayt-search-bar {
    width: 100%;
  }

  .search-display-flex {
    display: flex;
    z-index: auto;
    @include homefield.media-breakpoint-up('md') {
      z-index: 110;
    }
  }

  .search-close-sayt-close-icon {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: var(--primary-color);
    cursor: pointer;
    padding: 8px;
    &:focus-visible {
      outline: 1px dashed var(--focus-color);
    }
    svg path {
      fill: var(--primary-color);
    }
  }

  .dsg-clear-search-input {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    float: left;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    input {
      @include homefield.font('l', 'label');
      background-color: var(--search-bar-background-color);
      border: none;
      color: var(--search-bar-font-color);
      font-weight: 400 !important;
      width: 100%;
      @media screen and (min-width: 992px) {
        @include homefield.font('m', 'label');
      }
      &::placeholder {
        color: var(--search-bar-font-color);
      }
      &:hover {
        border: none;
        &::placeholder {
          color: var(--hmf-gray900);
          font-weight: 400;
        }
      }

      input::-ms-clear {
        display: none;
      }

      span {
        border-bottom: 1px solid var(--hmf-tertiary700);
        cursor: pointer;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 20px;
        position: absolute;
        right: 40px;
        top: 12px;
        @include homefield.media-breakpoint-up('md') {
          top: 8px;
        }
      }
    }

    .dsg-input-search-container {
      align-items: center;
      display: flex;
      height: 100%;
      width: 99%;

      svg {
        path {
          fill: var(--search-bar-icon-color);
        }
      }

      input {
        box-sizing: border-box;
        height: 100%;
        padding: 0;
        @include homefield.font('l', 'body');
      }
    }
  }

  .clear-input {
    display: none;
  }

  .dsg-search-border {
    @include homefield.media-breakpoint-up('md') {
      border-radius: 20px 20px 0px 0px;
    }
  }

  .search-backdrop {
    background-color: var(--color-black);
    left: 0;
    opacity: 0.6;
    overflow-y: hidden;
    z-index: 100;
    right: 0;
    top: 53px;
    bottom: 0;
    height: calc(100vh - 148px);
    margin: 0 -10px;
    width: calc(100% + 20px);
    border: none;
    padding: 0;

    @include homefield.media-breakpoint-up('md') {
      margin-left: -1000%;
      margin-right: -1000%;
      width: auto;
      height: 100vh;
    }
  }
  /*! purgecss start ignore */
  .header-search-bar {
    order: 8;
    width: 100%;
    @include homefield.media-breakpoint-up('xlg') {
      flex-grow: 1;
      order: 2;
      width: auto;
    }

    &.desktop-search-bar {
      display: none;
      @include homefield.media-breakpoint-up('xlg') {
        display: block;
      }
    }

    &.mobile-search-bar {
      display: block;
      @include homefield.media-breakpoint-up('xlg') {
        display: none;
      }
    }

    &.search-mobile {
      box-sizing: border-box;
      width: 100%;
    }
    &.search-tablet {
      box-sizing: border-box;
      width: 100%;
    }
    &.search-small-desktop {
      box-sizing: border-box;
    }

    &.sticky {
      background-color: var(--header-sticky-background-color);
      filter: drop-shadow(0px 4px 4px rgba(140, 143, 142, 0.1));
      margin: 0 !important;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 16;
      padding: 0.8rem var(--hmf-s);
    }

    &.sharing-row {
      border-bottom: 1px solid var(--hmf-primary50);
      padding-right: 90px !important;
    }
  }
  /*! purgecss end ignore */
  @media (max-width: 1023px) {
    #header-search-bar-sticky-anchor,
    #header-search-bar-sticky-ghost-div {
      width: 100%;
    }
  }

  .push-down-sayt-container {
    top: 76px !important;
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-header-scope {
  .expandable-ticker-wrapper {
    min-height: 48px;
    width: 100%;

    &.header-ticker-minimum {
      min-height: 48px;
    }

    button {
      width: 100%;
      -webkit-appearance: none;
      background: none;
      border: none;
      border-radius: var(--hmf-0);
      cursor: pointer;
      &:focus-visible {
        outline: 1px dashed var(--hmf-gray50);
        outline-offset: 5px;
      }
      svg {
        path {
          fill: white;
        }
      }
    }
    .expandable-ticker-expand {
      height: 32px;
      width: 32px;
      box-sizing: border-box;
    }

    @include homefield.media-breakpoint-up('sm') {
      height: 48px;
    }

    .expandable-ticker {
      width: calc(100% - 64px);
      max-width: 640px;

      .expandable-ticker-track {
        overflow: hidden;

        .slick-list {
          padding: 0;
        }

        .slick-track {
          display: flex;
          align-items: center;
        }

        .slick-slide {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: var(--hmf-0);
          padding: var(--hmf-0);
          & > div:first-child {
            max-width: 100%;
            max-height: 100%;
          }
          .expandable-ticker-item {
            .expandable-ticker-content-section {
              max-width: 100%;
              .expandable-ticker-top-section {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .expandable-ticker-bottom-section {
                overflow: hidden;
              }

              .expandable-ticker-bottom-text {
                max-width: calc(100% - 32px);

                @include homefield.media-breakpoint-up('sm') {
                  max-width: 100%;
                }
              }

              .expandable-ticker-bottom-text-content {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .expandable-ticker-link {
                white-space: nowrap;
                overflow: visible;
                color: var(--hmf-white);
                &:focus-visible {
                  outline: 1px dashed var(--hmf-gray50);
                }
              }
            }
          }
        }
      }
    }
  }
}

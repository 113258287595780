.FulfillmentButton {
  border-radius: var(--hmf-base-radius);
  border: 1px solid var(--hmf-border-secondary-subtle-static);
  flex-basis: 0px;
  cursor: pointer;
  min-height: 150px;

  &:focus {
    outline: var(--hmf-focus-outline);
    outline-offset: var(--hmf-xxxs);
  }

  &:hover {
    border: 1.5px solid var(--hmf-border-secondary-hover);

    .FulfillmentButton-content {
      svg {
        path {
          fill: var(--hmf-label-positive);
        }
      }
    }
  }

  .FulfillmentButton-content {
    text-align: left;

    p:last-of-type() {
      color: var(--hmf-gray700);
    }

    .FulfilmentButton-seeAvailableStores {
      color: var(--hmf-primary600);
    }

    svg {
      path {
        fill: var(--hmf-label-base);
      }
    }

    .not-available-link {
      color: var(--hmf-label-positive);
    }
  }

  &.FulfillmentButton-selected {
    border: 1.5px solid var(--hmf-border-secondary-static);

    svg {
      path {
        fill: var(--hmf-label-positive);
      }
    }
  }

  &.FulfillmentButton-disabled {
    border: 1px dashed var(--hmf-border-secondary-subtle-static);

    &:hover {
      border: 1.5px dashed var(--hmf-border-secondary-subtle-hover);
    }

    .FulfillmentButton-content {
      svg {
        path {
          fill: var(--hmf-label-disabled);
        }
      }
    }
  }

  &.FulfillmentButton-selected.FulfillmentButton-disabled {
    border: 1.5px solid var(--hmf-border-secondary-static);
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-footer-scope {
  .top-messages-grid {
    .top-section-card {
      padding: var(--hmf-s);
      border-bottom: 1px solid var(--hmf-border-base);

      @include homefield.media-breakpoint-up('md') {
        &:nth-of-type(1),
        &:nth-of-type(3) {
          padding-right: 0;
          padding-left: var(--hmf-m);
        }

        &:nth-of-type(2),
        &:nth-of-type(4) {
          padding-left: 0;
          padding-right: var(--hmf-m);
        }
      }

      @include homefield.media-breakpoint-up('xlg') {
        border-bottom: none;
        border-right: 1px solid var(--hmf-border-base);

        &:nth-of-type(1) {
          padding-left: var(--hmf-m);
          padding-right: var(--hmf-s);
        }

        &:nth-of-type(2) {
          padding: var(--hmf-s);
        }

        &:nth-of-type(3) {
          padding: var(--hmf-s);
        }

        &:nth-of-type(4) {
          padding-left: var(--hmf-s);
          padding-right: var(--hmf-m);
          border-right: none;
        }
      }
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.sayt-modal-container-wrapper {
  height: 100%;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 107;

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--hmf-background-inverse);
    opacity: 0.5;
    z-index: -1;
  }

  .sayt-modal-container {
    width: 100%;
    left: 0;
    border-radius: var(--hmf-sharp-radius) var(--hmf-sharp-radius) var(--hmf-soft-radius)
      var(--hmf-soft-radius);
    background: var(--hmf-background-base-subtle);
    box-shadow: 0px 2px 2px 0px rgba(26, 26, 26, 0.08);
    height: auto;
    overflow: hidden;
    position: absolute;
    top: -8px;

    @include homefield.media-breakpoint-up('md') {
      width: unset;
      left: 0;
      border-radius: var(--hmf-soft-radius);
      border: 1px solid var(--hmf-border-base);
    }
  }
}

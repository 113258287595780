.slick-next,
.slick-prev {
  background: var(--hmf-gray50);
  height: 44px;
  opacity: 0.75;
  text-align: center;
  width: 44px;
  z-index: 1;
}

.slick-next {
  align-items: center;
  display: flex;
  justify-content: center;
  right: 0;

  &:before {
    content: '';
    line-height: 1.3;
    opacity: 1;
  }
}

.slick-prev {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;

  svg {
    transform: rotate(180deg);
  }

  &:before {
    content: '';
    line-height: 1.3;
    opacity: 1;
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-footer-scope {
  .top-messages-grid {
    display: grid;
    max-width: 1600px;

    @include homefield.media-breakpoint-up('md') {
      grid-template-columns: 1fr 1fr;
    }

    @include homefield.media-breakpoint-up('xlg') {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.calia-react-footer-scope {
  .footer-columns {
    max-width: 1600px;
  }

  .footer-column {
    width: 100%;
  }

  .footer-column-items {
    width: 100%;
    gap: var(--hmf-s);
  }
}

.calia-react-header-scope.hmf-pl-theme,
.calia-react-header-scope.hmf-mj-theme {
  .nav-boxes-container {
    background-color: var(--mobile-nav-accent-background-color);
    flex-wrap: wrap;
    &__box {
      align-items: center;
      background-color: var(--color-white);
      box-shadow: 0px 2px 8px var(--nav-box-shadow-color);
      cursor: pointer;
      flex: 0 1 30%;
      flex-direction: column;
      height: auto;
      min-height: 95px;
      text-align: center;
      text-decoration: none;
      width: 95px;
      &:hover {
        box-shadow: 0px 2px 8px var(--nav-box-shadow-color-hover);
      }

      &--icon {
        background: var(--hmf-accent100);
        height: 37px;
        width: 37px;
        svg {
          height: 22px;
          width: 22px;
        }
      }

      &--link-title {
        color: var(--nav-menu-font-color);
        margin: 0;
        text-decoration: none;
      }
      &:focus-visible {
        outline: 1px dashed var(--focus-color);
        outline-offset: 5px;
      }
    }
  }
}

.LoadingSpinner {
  .LoadingSpinner-spinner {
    animation: LoadingSpinner-spinFrames 1s linear infinite;
    border: 6px solid var(--hmf-primary50);
    border-radius: 50%;
    border-top: 6px solid var(--hmf-tertiary600);
    height: 60px;
    width: 60px;
  }
}

@keyframes LoadingSpinner-spinFrames {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;
@use 'src/styles/_legacy-breakpoints.scss' as legacy-breakpoints;

.topMessage-container {
  align-items: center;
  display: flex;
  text-decoration: none;

  @include homefield.media-breakpoint-up('md') {
    display: none;
  }

  &--arrow {
    path {
      fill: var(--primary-color);
    }
  }

  &--icon {
    path {
      fill: var(--tertiary-color);
    }
  }

  @media (legacy-breakpoints.$mobile) {
    background-color: var(--desktop-nav-background-color);
    justify-content: center;
  }

  &--links {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      color: var(--primary-color);
      font-weight: normal;
      text-decoration: underline;
      text-decoration-color: var(--header-navbar-links-text-decoration-color);
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
    }

    &:visited {
      color: var(--primary-color);
    }

    &:focus-visible {
      box-sizing: border-box;
      outline: 1px dashed var(--focus-color);
    }

    @media screen and (max-width: 1176px) {
      width: max-content;
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.hmf-modal.sdd-modal {
  z-index: 110;
  .hmf-modal-container {
    width: 100% !important;
    @include homefield.media-breakpoint-up('lg') {
      width: 30% !important;
    }
    @include homefield.media-breakpoint-up('xlg') {
      width: 40% !important;
    }

    .hmf-modal-container-wrapper {
      .hmf-close-primary {
        background-color: inherit;
      }
    }
  }

  .sdd-modal-v2-content {
    width: 100%;
  }

  .sdd-modal-header {
    background-color: var(--hmf-gray100);
    border: 1px solid var(--hmf-gray200);
    padding: 18px;
  }

  .sdd-modal-instructions {
    p {
      color: var(--hmf-gray700);
    }
  }

  .sdd-modal-button {
    border-radius: 2px;
    flex-grow: 2;
    height: 44px;
    min-width: 125px;
  }

  .sdd-modal-input {
    gap: var(--hmf-xxs);
    flex-grow: 3;

    input {
      &::placeholder {
        user-select: none;
      }
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.main-header {
  background-color: var(--header-background-color);
  box-sizing: border-box;
  font-family: var(--primary-font-family);
  margin: 0 auto;
  width: auto;

  @include homefield.media-breakpoint-up('md') {
    @media screen and (max-width: 992px) {
      justify-content: space-around;
    }
  }

  .logo-menu-container {
    @include homefield.media-breakpoint-up('lg') {
      justify-content: var(--header-logo-container-justify-content);
    }
    flex-grow: 0.5;
    @include homefield.media-breakpoint-up('md') {
      flex-grow: 1;
    }
    @include homefield.media-breakpoint-up('xlg') {
      flex-grow: 0;
    }

    .logo-placeholder {
      width: 40px;
    }
  }

  &.header-border {
    border-bottom: 1px solid var(--header-border-color);
  }

  &.hide-header {
    animation: hide-header 0.3s ease-out;
    width: 100%;
  }

  &.sticky {
    animation: show-header 0.3s ease-out;
    max-height: 93px;
    overflow-y: hidden;
    top: 0;
    width: 100%;
    z-index: 105;

    @include homefield.media-breakpoint-up('md') {
      max-height: 83px;
    }

    @include homefield.media-breakpoint-up('xlg') {
      max-height: 93px;
    }
  }

  .header-container {
    max-width: 1600px;
  }
  .header__menu-container {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    text-align: center;
    @include homefield.media-breakpoint-up('md') {
      width: 56px;
      height: 56px;
    }
    @include homefield.media-breakpoint-up('xlg') {
      display: none;
    }
    &:focus-visible {
      background-color: var(--header-icon-focused-color);
      border: 1px dashed var(--header-icon-border-focused);
      border-radius: var(--hmf-soft-radius);
      outline: none;
    }
    &:hover {
      background-color: var(--header-icon-hover-color);
      border-radius: var(--hmf-soft-radius);
    }
    &:active {
      background-color: var(--header-icon-active-color);
      border-radius: var(--hmf-soft-radius);
    }
    p {
      color: var(--primary-color);
    }
    svg {
      path {
        fill: var(--primary-color);
      }
    }
    &--menu-sub-container {
      // increase tap area for the icons to 44x44
      padding: 10px;
    }
  }

  .Header_Logo {
    @media screen and (max-width: 992px) {
      text-align: center;
    }
    &:focus-visible {
      box-sizing: border-box;
      outline: 1px dashed var(--focus-color);
      outline-offset: 5px;
    }

    &.header-logo-mobile {
      align-self: flex-start;
    }
    @include homefield.media-breakpoint-up('lg') {
      padding-left: 0;
    }
    .dsg-logo {
      height: 42px;
      width: 96px;
      @include homefield.media-breakpoint-up('md') {
        height: 48px;
        width: 108px;
      }
    }
  }

  .hamburger-menu {
    outline: none;
    &:focus-visible {
      box-sizing: border-box;
      outline: 1px dashed var(--hmf-fill-secondary-active);
    }
    @include homefield.media-breakpoint-up('xlg') {
      order: 2;
    }

    .hamburger-icon {
      cursor: pointer;
      background-color: transparent;
      border: none;
      &:active {
        background: var(--hmf-fill-secondary-active);
        border-radius: var(--hmf-soft-radius);
      }
      &:hover {
        background-color: var(--hmf-fill-secondary-hover);
        border-radius: var(--hmf-soft-radius);
      }
      svg {
        height: 24px;
        width: 24px;
        @include homefield.media-breakpoint-up('lg') {
          height: 40px;
          width: 40px;
        }
      }

      .hamburger-text {
        color: var(--hmf-gray900);
      }
    }
  }

  .saytV2Enabled {
    @include homefield.media-breakpoint-up('lg') {
      z-index: 101;
    }
  }

  .sayt-no-border-bottom {
    border-bottom: none;
  }

  .dsg-search-wrapper-new-header {
    border-top: 1px solid var(--header-border-color);
    @include homefield.media-breakpoint-up('md') {
      border-top: none;
      flex-grow: 1;
    }
  }
  .hamburger-menu-new {
    color: var(--hmf-label-base);
    cursor: pointer;
    &:active {
      background: var(--hmf-fill-secondary-active);
      border-radius: var(--hmf-softer-radius);
    }
    &:hover {
      background-color: var(--hmf-fill-secondary-hover);
      border-radius: var(--hmf-softer-radius);
    }
    &:focus-visible {
      background-color: var(--hmf-fill-secondary-static);
      border: 1px dashed var(--hmf-border-focused);
      border-radius: var(--hmf-softer-radius);
      outline: none;
    }
  }

  @keyframes show-header {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes hide-header {
    from {
      max-height: 93px;
      position: fixed;
      top: 0;
      transform: translateY(0);
    }
    to {
      position: relative;
      transform: translateY(-100%);
    }
  }
}

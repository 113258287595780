.aos-header-container {
  .current-offers {
    background-color: var(--aos-current-offers-bg-color);
    color: var(--color-white);
    height: 50px;

    .current-offers-headline {
      height: 100%;

      .action-text-content {
        svg {
          height: 15px;
          margin-left: 6px;
          width: 15px;
          pointer-events: none;
        }
      }
    }

    .current-offers-list {
      background: var(--color-white);
      border: 1px solid var(--hmf-border-base);
      list-style: none;
      width: 300px;
      z-index: 10000;

      &.hidden {
        display: none;
      }

      li {
        border-bottom: 1px solid var(--hmf-border-base);
        color: var(--primary-color);
      }
    }
  }
}

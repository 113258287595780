@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-header-scope.hmf-g3-theme {

  .navbar-wrapper {
    max-width: 1600px;
  }

  .navbar-buttons {
    & > * {
     border-left: 1px solid var(--hmf-border-base)
    }
  }

  .cart-item-count {
    background-color: var(--hmf-fill-primary-static);
    color: var(--hmf-label-inverse-base);
    // We need a custom offset here so that the count indicator will appear slightly elevated
    // above the cart icon
    top: -6px;
    left: var(--hmf-s);
    height: var(--hmf-s);
    width: var(--hmf-s);
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-footer-scope {
  .footer-connect-with-us {
    .success-sms {
      color: var(--hmf-primary600);
    }

    .email-sign-up {
      max-width: 100%;
    }

    .mobile-alert-sign-up {
      max-width: 100%;
    }

    @include homefield.media-breakpoint-up('md') {
      .email-sign-up {
        width: 50%;
      }

      .mobile-alert-sign-up {
        width: 50%;
      }
    }

    .connect-with-us-button {
      border-radius: var(--hmf-sharp-radius);
    }

    .need-help-container {
      border-top: 1px solid var(--hmf-border-base);
      width: 100%;
    }

    input.sign-up-input {
      border: 1px solid var(--hmf-border-base-subtle);

      @include homefield.media-breakpoint-up('md') {
        max-width: 320px;
      }

      &:focus {
        border: 1px solid var(--hmf-border-tertiary-static);
        outline-color: transparent;
        outline-style: none;
      }

      &.dsg-react-input-error {
        background: var(--hmf-fill-negative-subtle);
        border: 1px solid var(--hmf-border-negative);
      }
    }

    .dsg-react-input-focused {
      visibility: visible !important;
    }

    .email-signup-label {
      background-color: var(--hmf-background-base-subtle);
      margin-left: var(--hmf-s);
      margin-top: -8px;
      padding-left: 1px;
      padding-right: 1px;
      visibility: hidden;

      &.dsg-react-input-error {
        color: var(--hmf-label-negative);
      }
    }

    .dsg-react-footer-sign-up-error-msg {
      color: var(--hmf-label-negative);
      width: 100%;
    }

    .social-media-links {
      gap: var(--hmf-m);

      a:hover {
        svg {
          path {
            fill: var(--button-static-bg-color);
          }
        }
      }
    }
  }
}

.quickview-overlay {
  background: rgba(186, 188, 187, 0.7);
  cursor: pointer;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 105;
}

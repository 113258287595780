.footerV2-container-2021 {
  background-color: var(--footer-background-color);

  *,
  :after,
  :before {
    box-sizing: border-box;
  }
}

.footerV2-filler-space {
  background-color: var(--primary-accent-color);
  height: 300px;
  @media screen and (min-width: 1680px) {
    height: 100px;
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.header-my-account-new {
  // increase tap area for the icons to 44x44
  padding: 10px 10px 10px 10px;
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  @include homefield.media-breakpoint-up('md') {
    height: 56px;
  }
  &:active {
    background-color: var(--header-icon-active-color);
    border-radius: var(--hmf-soft-radius);
  }
  &:hover {
    background-color: var(--header-icon-hover-color);
    border-radius: var(--hmf-soft-radius);
  }
  &:focus-visible {
    background-color: var(--header-icon-focused-color);
    border: 1px dashed var(--header-icon-border-focused);
    border-radius: var(--hmf-soft-radius);
    outline: none;
  }
  svg {
    &.user-icon {
      path {
        fill: var(--primary-color);
      }
    }
    &.pl-explorer-icon {
      path,
      circle {
        fill: var(--hmf-accent100);
        stroke: var(--hmf-accent100);
      }
    }
  }
}

.calia-react-footer-scope.hmf-pl-theme,
.calia-react-footer-scope.hmf-mj-theme {
  .footer-email-signup-form {
    @media (max-width: 991px) {
      align-items: flex-start;
    }

    @media (max-width: 1176px) {
      flex-direction: column;
    }
  }

  .footer-email-input {
    border: var(--footer-email-signup-input-border);
    width: 50%;

    @media (max-width: 991px) {
      margin-bottom: var(--hmf-xxs) !important;
    }

    @media (max-width: 549px) {
      width: 100%;
    }

    @media (min-width: 992px) and (max-width: 1176px) {
      width: 100%;
    }
  }

  .footer-email-submit {
    width: 50%;

    @media (max-width: 549px) {
      width: 100%;
    }

    @media (min-width: 992px) and (max-width: 1176px) {
      width: 100%;
    }
  }
}

.footer-email-signup {
  display: flex;
  flex-direction: column;

  .footer-email-signup-disclaimer {
    font-size: 12px;
    line-height: 20px;
  }
}

.footer-email-signup-form {
  &.footer-focused {
    .footer-email-input {
      &:focus {
        border: 1px solid var(--footer-email-signup-focused-border-color);
        outline: none;
        outline-style: none;
      }
    }

    &.footer-error {
      > .footer-email-input {
        border-bottom: 1px solid #eb004e;
        border-left: 1px solid #eb004e;
        border-right: none;
        border-top: 1px solid #eb004e;
        caret-color: #f44336;
      }

      > .footer-email-submit {
        border-bottom: 1px solid #eb004e;
        border-right: 1px solid #eb004e;
        border-top: 1px solid #eb004e;
      }
    }
  }
}

.footer-email-signup-form {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
}

.footer-email-error-text {
  background: #eb004e;
  border-radius: 2px;
  color: #fff;
  margin-top: 2px;
  padding: 4px;
}

.footer-email-input {
  border: none;
  box-sizing: border-box;
  height: 44px;
  width: 250px;

  &::placeholder {
    color: var(--footer-email-signup-input-placeholder-color);
    // using homefield variables here because we can't target the placeholder specifically with a
    // class name
    font-family: var(--font-primary);
    font-size: var(--body-font-size-l);
    font-style: normal;
    font-weight: var(--font-primary-regular-weight);
    line-height: var(--body-line-height-l);
    letter-spacing: var(--body-letter-spacing);
  }
}

.footer-email-submit {
  background: var(--drop-down-menu-button-background-color);
  border: var(--footer-signup-email-border);
  color: var(--drop-down-menu-button-text-color);
  height: 44px;
  width: 122px;

  &:hover {
    background: var(--drop-down-menu-button-onhover-background-color);
    border: var(--footer-signup-email-border-hover);
  }

  &:active {
    background: #f9e7e5;
  }

  &:focus {
    background: var(--drop-down-menu-button-onhover-background-color);
    outline: none;
    outline-style: none;
  }
}

.footer-email-submit:hover {
  cursor: pointer;
}

.footer-email-signup-success {
  color: var(--footer-email-signup-signup-success-color);

  span {
    color: #a4d4bd;
  }

  a {
    cursor: pointer;
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.same-day-delivery-new {
  color: var(--hmf-label-base);
  cursor: pointer;
  background-color: transparent;
  border: none;
  @include homefield.media-breakpoint-up('md') {
    height: 56px;
  }
  &:active {
    background: var(--hmf-fill-secondary-active);
    border-radius: var(--hmf-soft-radius);
  }
  &:hover {
    background-color: var(--hmf-fill-secondary-hover);
    border-radius: var(--hmf-soft-radius);
  }
  &:focus-visible {
    background-color: var(--hmf-fill-secondary-static);
    border: 1px dashed var(--hmf-border-focused);
    border-radius: var(--hmf-soft-radius);
    outline: none;
  }
  &.mobile-menu {
    width: 100%;
    height: auto;
    background-color: var(--hmf-fill-secondary-static-subtle);
    border-bottom: 1px solid var(--hmf-border-base);
    border-radius: 0;
    cursor: pointer;
    &:hover {
      background-color: var(--hmf-fill-secondary-hover-subtle);
    }
    &:focus-visible {
      outline: 1px dashed var(--hmf-border-focused);
      outline-offset: -4px;
      border: none;
      border-bottom: 1px solid var(--hmf-border-base);
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.footerLinks-container {
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1600px;
  @include homefield.media-breakpoint-up('md') {
    flex-direction: row;
  }
  &__linkCol {
    display: none;
    flex-direction: column;
    width: 20%;
    gap: var(--hmf-s);

    @include homefield.media-breakpoint-up('md') {
      display: flex !important;
    }
    &--links {
      display: block;
    }
    &--heading {
      color: var(--footer-links-heading-color);
    }
  }
}

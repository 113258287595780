@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.flyOutImage-container {
  box-shadow: none;
  position: relative;
  img {
    max-width: 100%;
    min-height: 150px;
  }
  .image-text {
    bottom: 0;
    color: var(--color-white);
    left: 0;
    position: absolute;
    right: 0;
    width: 70%;
    &:focus-within {
      box-sizing: border-box;
      outline: 1px dashed var(--color-white);
      outline-offset: -8px;
      z-index: 2;
    }
    .title {
      margin: var(--hmf-0) auto;
    }
    .cta {
      border-bottom: 2px solid transparent;
      color: var(--color-white);
      padding-bottom: 2px;
      text-decoration: none;
      text-decoration: none;
      &:hover {
        border-color: var(--color-white);
      }
      &:focus-visible {
        outline: none;
        outline-offset: -8px;
      }
      img {
        filter: invert(1);
      }
    }
  }
}

.below-image-cta-container {
  display: flex;
  flex-direction: column;
  .below-image-cta {
    color: var(--nav-menu-font-color);
    text-decoration: none;
    width: fit-content;
    &:first-of-type {
      @include homefield.media-breakpoint-up('m') {
        @include homefield.font('m', 'header-bold');
        font-weight: var(--font-display-weight);
      }
    }
    &:hover {
      border-bottom: 2px solid var(--secondary-color);
      margin-bottom: 22px !important;
    }
    &:focus-visible {
      box-sizing: border-box;
      outline: 1px dashed var(--focus-color);
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

// This CSS is kept the same as the existing CSS as once the new SAYT is implemented, the existing CSS will be removed.

.dsg-search-results-container {
  background-color: var(--search-results-box-background-color);
  display: flex;
  flex-direction: column;
  @include homefield.media-breakpoint-up('md') {
    height: auto;
    width: 100%;
  }

  .dsg-search-suggestion-result-container {
    align-items: center;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--hmf-gray200);
    display: flex;
    height: 56px;
    position: relative;
    width: 100%;
    &:hover {
      @media screen and (min-width: 992px) {
        background: var(--search-results-box-background-color);
        &:before {
          background-color: var(--secondary-color);
          content: '';
          height: 56px;
          left: 0;
          position: absolute;
          top: 0;
          width: 8px;
        }
      }
      .dsg-search-suggestion-result {
        color: var(--search-results-font-color);
        .dsg-search-suggestion-keyword {
          color: var(--search-results-font-color);
        }
      }
    }

    &:focus-within {
      outline: 1px dashed var(--focus-color);
      outline-offset: -5px;
    }

    .dsg-search-suggestion-result {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: block;
      outline: none;
      padding-left: var(--hmf-s);
      padding-right: var(--hmf-s);
      width: 100%;
      .dsg-search-suggestion-keyword {
        font-weight: 400;
      }
    }
    .dsg-search-suggestion-arrow-button {
      background: none;
      border: none;
      cursor: pointer;
      float: right;
      padding: 16px 20px 12px 11.25px;
      @include homefield.media-breakpoint-up('md') {
        padding: 12px 20px 12px 11.25px;
      }
      &:hover {
        svg {
          fill: var(--secondary-color); //#bb5811;
          &.history-icon {
            fill: var(--color-black);
          }
        }
      }
    }
    svg {
      fill: var(--color-black);
      transform: rotate(45deg);
      &.history-icon {
        height: auto;
        margin-right: 8px;
        width: auto;
        &:hover {
          fill: var(--color-black);
        }
      }
      &:hover {
        fill: var(--secondary-color);
      }
    }
  }

  h1 {
    @include homefield.font('s', 'label');
    border-bottom: 1px solid var(--hmf-gray200);
    color: var(--search-results-header-color);
    margin: 0;
    padding: 30px 0 13px 12px;
    text-transform: uppercase;
    &.recent-search-heading {
      padding: 12px 0 13px 12px;
    }
  }

  .dsg-search-result-products {
    margin-left: 0;
    @include homefield.media-breakpoint-up('md') {
      display: flex;
    }

    .dsg-search-suggested-container {
      align-items: flex-start;
      flex-direction: column;
      margin: 0 0 8px;
      width: -webkit-fill-available;
      @include homefield.media-breakpoint-up('xlg') {
        border-right: 1px solid var(--hmf-gray200);
        display: flex;
        flex: 1 0 50%;
        max-width: 50%;
      }
      > div {
        width: 100%;
      }
      .dsg-search-suggested-categories,
      .dsg-search-suggested-brands,
      .dsg-search-suggested-products {
        .dsg-search-suggestion-result-container {
          background: var(--search-results-box-background-color);
        }
      }

      .dsg-search-suggested-keywords {
        .dsg-search-suggestion-result {
          font-weight: bold;
          white-space: nowrap;
        }

        .top-suggestions {
          background-color: var(--color-white);
          border-bottom: 1px solid var(--hmf-gray200);
          color: var(--search-results-font-color);
          cursor: default;
          height: 56px;
          &:hover {
            color: var(--search-results-font-color);
          }
        }
      }
    }
    .dsg-search-products-container {
      border-left: 1px solid var(--hmf-gray300);
      width: 100%;
      &:focus-visible {
        outline: 1px dashed var(--focus-color);
        outline-offset: -10px;
      }
    }
    .dsg-search-top-products {
      color: var(--search-results-header-color);
      margin: 0;
      text-align: left;
      text-transform: uppercase;
    }
  }

  .dsg-search-products {
    @include homefield.media-breakpoint-up('md') {
      display: flex;
      flex: 0 0 50%;
      flex-wrap: wrap;
      padding: 0 12px;
    }
  }

  .close-button-wrapper {
    position: sticky;
    top: 0;
    z-index: 110;
    .close-button {
      border: none;
      height: 56px;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 51px;
      background-color: var(--hmf-fill-tertiary-static);
      color: var(--hmf-label-inverse-base);
      cursor: pointer;
      svg {
        path {
          fill: var(--hmf-label-inverse-base);
        }
      }
      &:focus-visible {
        border: 1px dashed var(--color-white);
        box-sizing: border-box;
        outline: none;
      }
      &:hover {
        opacity: 0.75;
      }
    }
  }

  .dsg-search {
    &-link {
      color: black;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 16px;
      text-decoration: none;
    }

    .search-result-row {
      margin: 0 0 8px;
      padding: 16px 0;
      display: flex;
      @include homefield.media-breakpoint-up('md') {
        display: block;
      }
    }

    &-price {
      color: var(--hmf-primary900);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 18px;
    }

    &-suggestion {
      &-result {
        color: var(--search-results-font-color);
        text-decoration: none;
      }
    }

    &-view-all-results {
      text-align: center;
      padding: 48px 0 32px 0;
      &__link {
        color: var(--nav-menu-font-color);
        margin-left: auto;
        margin-right: auto;
        min-width: 50%;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: fit-content;

        &:hover {
          color: var(--nav-menu-font-color);
          text-decoration: solid var(--secondary-color) underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 4px;
        }
        &:active {
          text-decoration-thickness: 4px;
        }
        &:focus-visible {
          outline: 1px dashed var(--focus-color);
          outline-offset: 5px;
        }
      }
    }
  }
}

.calia-react-header-scope.hmf-pl-theme .plf-header-container,
.calia-react-footer-scope.hmf-pl-theme .plf-footer {
  @extend .calia-react-header-scope;

  // TYPOGRAPHY
  --font-family-primary: var(--primary-font-family);
  --primary-font-family: 'Graphik', 'sans-serif';
  --primary-font: 'Graphik', 'sans-serif';

  // HEADER
  --header-background-color: #6c846f;
  --header-navbar-background-color: var(--hmf-tertiary100);
  --header-links-font-family: 'Graphik', 'sans-serif';
  --header-links-underline-color: #6da300;
  --header-mobile-links-color: #6c846f;
  --header-mobile-links-active-color: #e2d6b9;
  --header-mobile-links-hover-color: #f8f6f4;
  --header-mobile-links-container-border-color: #6c846f;

  // FOOTER
  --footer-links-heading-color: var(--hmf-gray50);
  --footer-back-ground-color: #6c846f;
  --footer-links-font-family: 'Graphik', 'sans-serif';
  --footer-links-underline-color: #6da300;

  //GREEN
  --color-green-base-700: var(--hmf-primary700);
  --color-green-base-800: var(--hmf-primary800);
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.search-bar-container-new {
  width: 100%;
  order: 1;
  @include homefield.media-breakpoint-up('xlg') {
    width: unset;
    flex-grow: 1;
    order: unset;
  }

  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    padding: var(--hmf-xs) var(--hmf-s);
    background-color: var(--header-sticky-background-color);
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
  .search-container {
    border-radius: var(--hmf-soft-radius);
    border: 1px solid var(--hmf-border-secondary-subtle-static);
    background: var(--hmf-fill-secondary-static-subtle);
    height: 48px;
    &:hover {
      border: 1px solid var(--hmf-border-secondary-subtle-hover);
      background: var(--hmf-fill-secondary-hover-subtle);
      .search-bar-input::placeholder {
        color: var(--hmf-label-secondary-hover-subtle);
      }
      .search-icon-container-left {
        path {
          fill: var(--hmf-label-secondary-subtle-hover);
        }
      }
    }
    &:focus-within,
    &:focus,
    &:focus-visible {
      border: 1.5px solid var(--hmf-border-focused);
      background: var(--hmf-fill-secondary-static-subtle);
      .search-bar-input::placeholder {
        color: var(--hmf-label-secondary-subtle-static);
      }
      .search-icon-container-left {
        path {
          fill: var(--hmf-label-secondary-subtle-active);
        }
      }
    }
    &:active {
      border: 1.5px solid var(--hmf-border-secondary-subtle-active);
      background: var(--hmf-fill-secondary-active-subtle);
      .search-bar-input::placeholder {
        color: var(--hmf-label-secondary-subtle-static);
      }
      .search-icon-container-left {
        path {
          fill: var(--hmf-label-secondary-subtle-active);
        }
      }
    }
  }
  .search-bar-input {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    color: var(--hmf-label-base);
    margin-left: var(--hmf-0);
    transition: margin-left 0.1s ease;
    &::placeholder {
      color: var(--hmf-label-secondary-subtle-static);
    }
    &:focus {
      outline: none;
      background: none;
    }
  }
  .search-icon-container-left {
    path {
      fill: var(--hmf-label-secondary-subtle-static);
    }
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.1s ease, transform 0.1s ease;

    &.hidden {
      opacity: 0;
      transform: scale(0);
    }
  }
  .search-icon-container-left.hidden + .search-bar-input {
    margin-left: -16px; /* Shift input field to the left when icon is hidden */
    padding-left: var(--hmf-0);
  }
  .clear-search-icon-container-right {
    .clear-button,
    .search-button {
      border: none;
      background: none;
      height: auto;
      opacity: 1;
      transition: opacity 0.1s ease;
      &.hidden {
        display: none;
        opacity: 0;
      }
      svg {
        path {
          fill: var(--hmf-label-base-subtle);
        }
      }

      &:focus-visible {
        outline: 1px dashed var(--hmf-border-focused);
        outline-offset: -2px;
      }
    }

    .hmf-divider-vertical {
      background-color: var(--hmf-border-base-prominent);
      height: 24px;
    }
    .popover-container {
      z-index: 110;
      display: none;
      @include homefield.media-breakpoint-up('lg') {
        display: flex;
      }
      .hmf-modal-container {
        border-radius: var(--hmf-soft-radius);
        background: var(--hmf-fill-inverse);
      }
    }
  }
}

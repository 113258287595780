@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-footer-scope {
  .footer-score-more {
    background-color: var(--score-more-background-color);
    color: var(--hmf-gray50);

    @include homefield.media-breakpoint-up('xlg') {
      padding-left: 64px !important;
    }

    &:before {
      border-color: transparent var(--hmf-background-base-subtle) transparent transparent;
      border-style: solid;
      border-width: 40px;
      content: '';
      display: block;
      height: 0;
      margin-left: -65px;
      margin-top: -65px;
      position: absolute;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 0;

      @include homefield.media-breakpoint-up('m') {
        margin-left: -64px;
        margin-top: -88px;
      }

      @include homefield.media-breakpoint-up('xlg') {
        margin-left: -104px;
        margin-top: -88px;
      }
    }

    .score-card-item {
      border-bottom: 1px solid var(--hmf-gray50);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

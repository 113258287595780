@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;
@use 'src/styles/_legacy-breakpoints.scss' as legacy-breakpoints;

.calia-react-header-scope {
  .header-ticker-minimum {
    min-height: 64px;

    @include homefield.media-breakpoint-up('lg') {
      min-height: 48px;
    }
  }
}
.rh-ticker-container {
  background-color: var(--ticker-background-color);
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  min-height: 48px;
  padding: 0 15px 0;
  width: 100%;

  @media (max-width: legacy-breakpoints.$mobile-width) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .app-ticker-message {
    &:first-child {
      .ticker {
        &__section {
          &::before {
            border-left: 0;
            @media (max-width: legacy-breakpoints.$mobile-width) {
              border-top: 0;
            }
          }
        }
      }
    }

    a {
      box-sizing: border-box;
      overflow: hidden;
      width: 100%;
      @media (max-width: legacy-breakpoints.$mobile-width) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      .ticker__message {
        align-self: baseline;
        display: inline-block;
        margin-bottom: 0;

        &--main {
          padding-right: 1vw;
          @media (max-width: legacy-breakpoints.$mobile-width) {
            padding-right: 0;
          }
        }
        @media (max-width: legacy-breakpoints.$mobile-width) {
          align-self: center;
        }
      }
    }

    .ticker {
      &__section {
        &::before {
          border-left: 1px solid var(--ticker-color);
          bottom: 0;
          content: '';
          height: 90%;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          @media (max-width: legacy-breakpoints.$mobile-width) {
            border-top: 1px solid var(--ticker-color);
            bottom: auto;
            height: 1px;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            width: 80%;
          }
        }
      }

      &__message {
        color: var(--ticker-color);
        font-family: var(--ticker-font-family);
        font-size: var(--ticker-font-size);
        font-style: var(--ticker-font-style);
        font-weight: var(--ticker-font-weight);
        letter-spacing: var(--ticker-letter-spacing);
        line-height: var(--ticker-line-height);
        text-align: center;
        text-transform: var(--ticker-text-transform);
        &--disclaimer {
          font-family: var(--ticker-link-font-family);
          font-size: var(--ticker-link-font-size);
          font-style: var(--ticker-link-font-style);
          font-weight: var(--ticker-link-font-weight);
          letter-spacing: var(--ticker-link-letter-spacing);
          line-height: var(--ticker-link-line-height);
          text-transform: var(--ticker-link-text-transform);
        }
      }
    }
  }

  &.ticker-dsg--multi {
    .ticker {
      &__section {
        a {
          flex-direction: column;
          padding-left: 1vw;
          @media (max-width: legacy-breakpoints.$mobile-width) {
            padding-left: 0;
          }
        }
      }
    }
  }

  &.ticker-dsg {
    padding: 0;
    .ticker {
      &__section {
        padding: 8px;

        @media (max-width: legacy-breakpoints.$mobile-width) {
          padding: 8px 31px;
        }
      }

      &__message {
        &--hide {
          display: none;
        }

        &--disclaimer {
          font-size: var(--ticker-link-font-size);
        }

        &--underline {
          text-decoration: underline;
        }
      }
    }
  }

  &.ticker-gg {
    .ticker {
      &__section {
        padding: 8px;
      }

      &__message {
        &--hide {
          display: none;
        }

        &--bold {
          font-weight: bold;
        }

        &--disclaimer {
          font-size: var(--ticker-link-font-size);
          font-weight: 300;
        }

        &--underline {
          text-decoration: underline;
        }
      }
    }
  }
}

.accordion-container {
  display: block;
  &__accordion {
    display: block;
    &--category {
      align-items: center;
      background-color: var(--hmf-fill-secondary-static);
      border: 1px solid var(--hmf-gray200);
      border-left: none;
      border-right: none;
      border-top: none;
      box-sizing: border-box;
      color: var(--nav-menu-font-color);
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      position: relative;
      text-align: left;
      text-decoration: none;
      width: 100%;
      &:focus-visible {
        box-sizing: border-box;
        outline: 1px dashed var(--focus-color);
        outline-offset: -5px;
        z-index: 2;
      }
      &:hover {
        background-color: var(--hmf-fill-secondary-hover);
      }
      &:active {
        background-color: var(--hmf-fill-secondary-static);
      }
    }
    &--sublink-container {
      background-color: var(--hmf-fill-secondary-static);
      flex-direction: column;
      &.image-cta-accordion {
        position: relative;
      }
    }
    &--sublinks {
      color: var(--nav-menu-font-color);
      text-decoration: none;
      &:hover {
        font-weight: bold;
        text-decoration: underline solid var(--nav-menu-hover-color);
        text-decoration-thickness: 2px;
        text-underline-offset: 2px;
      }
      &:focus-visible {
        outline: 1px dashed var(--focus-color);
        outline-offset: -10px;
      }
    }
  }
  .flag {
    background-color: var(--accordion-flag-background-color);
    box-shadow: 0 8px 8px var(--hmf-gray200);
    height: 100%;
    left: 0;
    padding-left: var(--hmf-xxxs);
    position: absolute;
    top: 0;
    width: 5px;
  }
  .ptitle {
    width: 100%;
  }
  .accordion-box-shadow {
    box-shadow: -2px 8px 8px var(--hmf-gray200);
  }
}

.calia-react-footer-scope {
  .footer-links-container {
    gap: var(--hmf-m);
    max-width: 1400px;
  }

  .footer-link {
    flex-basis: 224px;
  }

  .site-feedback-button {
    appearance: none;
    background: none;
    border: none;
    color: var(--hmf-label-base);
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline var(--button-static-bg-color);
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;
@import './CustomArrows.scss';
@import './CustomDots.scss';

.quickview-imageViewer {
  background-color: var(--hmf-fill-base);
  height: 239px;

  .image-box {
    height: 189px;
  }

  @include homefield.media-breakpoint-up('md') {
    height: 308px;
  }

  .slick-slide {
    opacity: 0.25;
    img {
      height: auto;
      margin: 0 auto;
      max-height: 189px;
      max-width: 85%;

      @include homefield.media-breakpoint-up('md') {
        max-height: 258px;
      }
    }
  }

  .slick-track {
    align-self: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex: 1 0 auto;
    height: 189px;

    @include homefield.media-breakpoint-up('md') {
      height: 258px;
    }

    .slick-slide {
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex: 1 0 auto;
      height: auto;
      justify-content: center;

      img {
        float: right;
        margin: 0;
      }
    }

    .slick-current + .slick-slide {
      img {
        float: left;
        margin: 0;
      }
    }

    .slick-current {
      img {
        float: none;
        margin: 0 auto;
      }
    }
  }

  .slick-current {
    opacity: 1 !important;
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-header-scope.hmf-pl-theme {
  .plf-header-container {
    .plf-donate-button-wrapper {
      width: 100%;

      a {
        color: var(--color-white);

        &:hover {
          background-color: var(--color-green-base-700);
          box-shadow: 0 4px 5px -2px grey;
          transition: 0.3s;
        }
      }
    }

    .plf-header {
      .hmf-modal-container {
        width: 100%;
        background-color: var(--hmf-background-base-subtle);
      }
      background-color: var(--hmf-primary500);
      color: var(--hmf-gray50);

      a {
        color: var(--hmf-gray50);

        &:focus-visible {
          box-sizing: border-box;
          outline: 2px dashed var(--hmf-secondary500);
          outline-offset: 5px;
        }
      }

      svg.plf-logo {
        path {
          fill: var(--hmf-accent100);
        }
      }

      .logo-button {
        @include homefield.media-breakpoint-up('md') {
          align-items: baseline;
        }
      }

      .L0-links {
        :hover {
          text-decoration: underline;
          text-decoration-color: var(--hmf-label-inverse-base);
          text-decoration-thickness: 2px;
          text-underline-offset: 4px;
        }
      }

      .mobile {
        &-menu-bar {
          height: 60px;
        }

        &-menu-btn {
          &-open {
            border: none;
            cursor: pointer;
            background-color: var(--hmf-gray50);
            width: 63px;

            &:active {
              background-color: var(--hmf-tertiary200);
            }

            &:hover {
              background-color: var(--hmf-tertiary100);
            }

            &:focus-visible {
              box-sizing: border-box;
              outline: 2px dashed var(--hmf-secondary500);
              outline-offset: -5px;
            }
          }
        }

        &-l0-links {
          a {
            color: var(--hmf-label-base);
            width: 100%;

            &:focus-visible {
              outline-offset: -5px;
              outline: 1px dashed var(--hmf-border-focused);
            }
          }
        }

        &-link {
          height: 56px;
          border-bottom: 1px solid var(--hmf-border-base);

          &:hover {
            background-color: var(--hmf-fill-secondary-hover);
          }

          &:active {
            background-color: var(--hmf-fill-secondary-active);
          }
        }
      }
    }
  }
}

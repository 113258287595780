.calia-react-header-scope.hmf-sm-theme {
  z-index: 4;

  .rc-subheader-dropdown {
    background-color: var(--color-green-base-600);
    left: 0;
    letter-spacing: 0.5px;
    padding: 66px 50px;
    right: 0;
    top: 86px;
    z-index: 10;
  }

  .category-title-link {
    border: solid 1px transparent;
    color: var(--hmf-gray50);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--hmf-gray50);
    }
  }

  .rc-dropdown {
    &-menu {
      -webkit-box-align: center;
      -ms-flex-align: center;
    }

    &-links {
      color: var(--hmf-primary900);
      font-size: 12px;
      text-align: left;
    }

    &-title {
      -webkit-box-flex: 1;
      -ms-flex: auto;
      display: inline-block;
      flex: auto;
      outline: none;
      outline-style: none;

      &:focus {
        border: 1px dashed var(--hmf-primary600);
        box-sizing: border-box;
      }

      &:hover {
        background-color: var(--color-green-base-600);
        color: var(--hmf-gray50);

        span {
          background-color: var(--color-green-base-600);
          cursor: default;
          text-decoration: underline;
          text-decoration-color: var(--hmf-primary300);
          text-decoration-thickness: 1px;
          text-underline-offset: 4px;
        }
      }

      > span {
        cursor: pointer;
        display: inline-block;
        font-size: 24px;
        padding: 28.5px 25px;
        text-decoration: none;
        text-transform: var(--header-navbar-title-text-transform);

        @media screen and (max-width: 1440px) and (min-width: 1280px) {
          padding: 28.5px 35px;
        }
        @media screen and (max-width: 1024px) and (min-width: 768px) {
          padding: 28.5px 8px;
        }
      }
    }
  }

  .rc-dropdown-title:hover + .rc-dropdown-links {
    display: block;
  }
}

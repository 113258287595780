.calia-react-header-scope,
.calia-react-footer-scope {
  // COMMON COLORS
  --color-black: var(--hmf-gray900);
  --color-white: var(--hmf-gray50);
  --focus-color: var(--hmf-primary500);
  --font-color-primary: var(--hmf-gray900);
  --primary-font-color: var(--hmf-gray900);
  --common-focus-color: var(--hmf-gray600);
  --secondary-color-light: #2d523f;
  --primary-accent-color: var(--hmf-gray200);
  --tertiary-accent-color: var(--hmf-primary400);
  --close-icon-background-color: var(--hmf-gray900);
  --store-details-check-icon-color: var(--hmf-primary500);

  // BUTTON COMMON STYLES
  --button-active-secondary-bg-color: #e3e4e4;
  --button-active-secondary-border: 2px solid #babcbb;
  --button-disabled-bg-color: #e3e4e4;
  --button-disabled-color: #8c8f8e;
  --button-disabled-secondary-bg-color: #fff;
  --button-disabled-secondary-border: 2px solid #e3e4e4;
  --button-disabled-secondary-color: #8c8f8e;
  --button-disabled-tertiary-color: #8c8f8e;
  --button-hover-secondary-bg-color: #eaebeb;
  --button-secondary-color: #191f1c;
  --button-static-secondary-border: 2px solid #191f1c;
  --button-static-tertiary-bg-color: transparent;
  --button-tertiary-border: 0px;
  --button-tertiary-color: #191f1c;

  // HEADER
  --header-background-color: var(--hmf-gray50);
  --header-border-color: var(--hmf-gray200);
  --header-my-account-icon-color: var(--hmf-primary900);
  --header-cart-icon-height: 30px;
  --header-cart-icon-width: 26px;
  --header-my-account-signin-fill-color: #797979;
  --header-navbar-links-text-decoration-color: var(--hmf-secondary500);
  --header-navbar-nav-icon-fill-color: #e3e4e4;
  --header-search-backdrop-top: 177px;
  --header-search-bar-sticky-background-color: var(--hmf-gray50);
  --header-sticky-background-color: var(--hmf-gray50);

  // DROPDOWN MENU
  --drop-down-menu-button-background-color: var(--hmf-gray50);
  --drop-down-menu-button-text-color: var(--hmf-primary500);

  // NAVIGATION
  --desktop-nav-background-color: var(--hmf-gray100);
  --desktop-nav-link-color: var(--hmf-primary800);
  --desktop-nav-link-visited-color: var(--hmf-gray900);

  // NAV MENU
  --nav-menu-background-color: var(--hmf-gray50);

  // MOBILE NAVIGATION
  --mobile-nav-accent-background-color: var(--hmf-gray100);
  --mobile-nav-border-styling: 1px solid var(--hmf-gray200);
  --mobile-nav-border-spacer-styling: 1px solid var(--hmf-gray200);

  // NAV BOXES
  --nav-box-shadow-color: var(--hmf-gray200);

  // CART
  --oval-count-font-color: var(--hmf-gray50);

  // SEARCH
  --search-bar-background-color: var(--hmf-gray100);
  --search-bar-border-color: var(--hmf-gray400);
  --search-bar-font-color: var(--hmf-gray700);
  --search-bar-hover-border-color: var(--hmf-gray500);
  --search-results-box-background-color: var(--hmf-gray100);
  --search-results-font-color: var(--hmf-gray700);
  --search-results-header-color: var(--hmf-gray700);

  // SEARCH/SAYT V2
  --sayt-results-font-color: var(--hmf-gray900);

  // FOOTER
  --bottom-footer-links-background-color: var(--hmf-gray100);
  --footer-links-heading-color: var(--hmf-gray50);
  --footer-logo-width: 182px;

  // EMAIL SIGNUP
  --footer-email-signup-input-placeholder-color: rgba(0, 0, 0, 0.54);

  // QUICKVIEW
  --quick-view-background-color: var(--hmf-gray100);
  --quick-view-image-container-background-color: var(--hmf-gray50);
  --quick-view-alert-warn-background-color: #fff8e2;
  --quick-view-alert-warn-border-color: var(--hmf-yellow300);
  --quick-view-attribute-selected-background-color: #000;

  // TOOLTIP
  --tooltip-text-color: var(--hmf-gray900);
  --tooltip-background-color: var(--hmf-gray50);
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
  --tooltip-border-color: var(--hmf-gray700);

  // TOP PRODUCT
  --top-product-price-font-size: 14px;
  --top-product-price-font-weight: 400;
  --top-product-price-letter-spacing: 1;
  --top-product-price-line-height: var(--subhead-line-height-l);

  // TICKER
  --ticker-background-color: var(--hmf-gray50);
  --ticker-color: var(--hmf-gray50);
  --ticker-font-size: var(--body-font-size-m);
  --ticker-font-style: normal;
  --ticker-font-weight: 700;
  --ticker-letter-spacing: 0.5px;
  --ticker-line-height: var(--body-line-height-s);
  --ticker-link-font-size: 1rem;
  --ticker-link-font-style: normal;
  --ticker-link-font-weight: 500;
  --ticker-link-letter-spacing: 0;
  --ticker-link-line-height: 1.4rem;
  --ticker-link-text-transform: none;
  --ticker-text-transform: none;
}

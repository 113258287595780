.footerEmailSignUp-container {
  margin-bottom: -150px;
  max-width: 100%;
  transform: translateY(-50%);

  .footer-email-input {
    color: var(--footer-secondary-color);
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: -50px;
  }

  &__content-wrapper {
    flex-direction: column;
    @media screen and (min-width: 1280px) {
      flex-direction: row;
    }

    @media screen and (max-width: 1023px) {
      &--image {
        width: 100%;
      }
    }
  }
  &__text-wrapper {
    background-color: var(--color-white);
    color: var(--footer-secondary-color);

    &--svg {
      height: 21px;
      vertical-align: baseline !important;
      width: 40px;

      path {
        fill: var(--secondary-color);
      }
    }
  }
}

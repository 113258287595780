@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-header-scope.hmf-g3-theme {
  .dsg-header {
    background-color: #eaebeb;
    color: var(--font-color-primary);
    font-family: var(--mobile-story-find-store-font-family);
    font-size: 1.3rem;

    *,
    :after,
    :before {
      box-sizing: border-box;
    }
  }

  .subheader {
    &-wrapper {
      min-height: 72px;
      border-bottom: 1px solid var(--hmf-border-base);
    }
    &-inner {
      .mobile-menu-container {
        height: 100%;
        .menu-items-container {
          background-color: var(--hmf-fill-secondary-static);
        }
        .utility-menu-container {
          background-color: var(--hmf-fill-secondary-static-subtle);
          height: 100%;
          .utility-menu-item {
            color: var(--hmf-fill-inverse);
          }
        }
      }

      border-bottom: 1px solid var(--hmf-border-base);
      @include homefield.media-breakpoint-up('md') {
        border: none;
      }
    }

    &-buttons {
      > button {
        > span {
          padding: 0 8px;
          > span {
            display: none;

            @include homefield.media-breakpoint-up('md') {
              display: inline;
            }
          }
        }
      }
      min-width: 0px;

      @include homefield.media-breakpoint-up('lg') {
        display: none;
      }
    }
  }

  .header-menu-container {
    gap: var(--hmf-m);
    width: 100%;

    @include homefield.media-breakpoint-up('lg') {
      width: auto;
    }
  }

  .header-shopping-bag-mobile {
    height: 48px;
    width: 48px;
  }

  .header-cart-count-mobile {
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    left: 7px;
    text-align: center;
    top: 3px;
    width: 14px;
    z-index: 1111;
  }
}

.calia-react-footer-scope.hmf-pl-theme,
.calia-react-footer-scope.hmf-mj-theme {
  .footer-accordion-container {
    &__accordion {
      &--category {
        background-color: var(--footer-secondary-color);
        border: 1px solid var(--tertiary-accent-color);
        border-left: none;
        border-right: none;
        color: var(--footer-links-heading-color);
        &:hover {
          background-color: var(--bottom-footer-links-background-color);
        }
        svg {
          path {
            fill: var(--footer-links-heading-color);
          }
        }
      }

      &--sublink-container {
        background-color: var(--footer-secondary-color);
      }

      &--sublinks {
        color: var(--footer-primary-color);
        &:hover {
          color: var(--footer-primary-color);
          font-weight: 700 !important;
          text-decoration: underline solid var(--tertiary-color);
        }
        &:active,
        &:visited {
          color: var(--footer-primary-color);
        }
        &:focus-visible {
          box-sizing: border-box;
          outline: 1px dashed var(--focus-color);
        }
      }
    }
  }
}

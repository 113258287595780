@use 'src/styles/_legacy-breakpoints.scss' as legacy-breakpoints;

.quickview-container {
  //Scoped imports to main quickview-container class
  @import './components/Content/QuickViewModal.scss';
  @import './components/Content/Content.scss';
  @import './components/ImageViewer/styles/ImageViewer.scss';
  @import './components/Overlay/Overlay.scss';
  @import './components/Attributes/AttributeGroup.scss';
  @import './components/ActionButtons/ActionButtons.scss';
  @import './components/Badge/Badge.scss';
  @import './components/Link/Link.scss';
  @import './components/Skeleton/Skeleton.scss';
  @import './components/PreOrderMessaging/PreOrderMessaging.scss';

  *,
  :after,
  :before {
    box-sizing: border-box;
  }
}

.calia-react-header-scope.hmf-pl-theme {
  .quickview {
    .product-title {
      p {
        font-size: 32px;
        line-height: 36px;

        @media screen and (max-width: legacy-breakpoints.$mobile-width) {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }
}

.quickview-button-edit-product-container {
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  column-gap: var(--hmf-xxs);

  .edit-mobile {
    grid-template-columns: 0.33fr 0.67fr;
  }
}

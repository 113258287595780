@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.background-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  height: 100%;
  left: 0;
  margin-left: -100%;
  margin-right: -100%;
  overflow-y: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}

.desktop-nav-container {
  display: none;

  @include homefield.media-breakpoint-up('xlg') {
    background-color: var(--hmf-gray50);
    border-bottom: 1px solid var(--hmf-gray200);
    display: block;
    margin: 0 auto;

    &__nav {
      background-color: var(--hmf-gray50);
      display: flex;
      justify-content: space-evenly;
      margin: 0 auto;
      max-width: 1600px;
      position: relative;
      z-index: 2;

      a {
        color: var(--desktop-nav-link-color);
        margin-bottom: var(--hmf-xxs);
        text-decoration: none;

        &:hover {
          border-bottom: 8px solid var(--desktop-nav-link-hover-border-color);
          color: var(--desktop-nav-link-hover-color);
          font-weight: var(--font-primary-bold-weight);
          margin-bottom: var(--hmf-0);
        }

        &.clearance,
        &.clearance:visited {
          color: var(--hmf-red900);
        }

        &.active {
          border-bottom: 8px solid var(--desktop-nav-title-border-color);
          font-weight: var(--font-primary-bold-weight);
          margin-bottom: var(--hmf-0);
        }

        &:focus-visible {
          box-sizing: border-box;
          outline: 1px dashed var(--focus-color);
        }

        &:visited {
          color: var(--desktop-nav-link-visited-color);
        }
      }
      &.active {
        padding-bottom: 0px !important;
      }

      .hidden-item {
        height: 0;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none;
        visibility: hidden;
      }
    }

    &__menu {
      animation: slide-down 0.5s ease;
      -webkit-animation: slide-down 0.5s ease;

      @keyframes slide-down {
        from {
          transform: translateY(-100%);
        }

        to {
          transform: translateY(0%);
        }
      }
      background: var(--nav-menu-background-color);
      box-shadow: 0px 4px 8px rgba(140, 143, 142, 0.1);
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      left: 16px;
      margin: 0 auto;
      max-width: 1568px;

      position: absolute;
      right: 16px;
      z-index: 101;

      &__column {
        display: flex;
        flex-direction: column;
        width: 25%;

        &__category {
          display: flex;
          flex-direction: column;

          &--heading {
            color: var(--nav-menu-font-color);
            text-decoration: none;
            width: fit-content;

            &:hover {
              //reducing the margin bottom by 2px on hover so the border doesn't move the layout
              border-bottom: var(--desktop-nav-column-category-hover-border);
              margin-bottom: 14px !important;
            }

            &:focus-visible {
              box-sizing: border-box;
              outline: 1px dashed var(--focus-color);
            }
          }

          .sublinks {
            color: var(--nav-menu-font-color);
            text-decoration: none;
            width: fit-content;

            &:hover {
              //reducing the margin bottom by 2px on hover so the border doesn't move the layout
              border-bottom: var(--desktop-nav-column-category-hover-border);
              margin-bottom: 10px !important;
            }

            &:focus-visible {
              box-sizing: border-box;
              outline: 1px dashed var(--focus-color);
            }
          }
        }

        .flyOutImage-container {
          margin-top: -var(--hmf-m);
          position: relative;

          img {
            max-width: 100%;
          }

          .image-text {
            bottom: 0%;
            position: absolute;
            width: 90%;

            .image-text-link {
              border: 1px dashed transparent;
              display: block;
              text-decoration: none;

              .cta {
                border-bottom: 2px solid transparent;
                color: var(--color-white);
                padding-bottom: 2px;
                text-decoration: none;
                width: fit-content;

                img {
                  filter: invert(1);
                }
              }

              &:hover {
                .cta {
                  border-color: var(--color-white);
                }
              }

              &:focus {
                border-color: var(--color-white);
                outline: none;
              }
            }

            .title {
              color: var(--color-white);
              margin: var(--hmf-0) auto;
            }
          }
        }

        .below-image-cta-container {
          display: flex;
          flex-direction: column;

          .below-image-cta {
            color: var(--nav-menu-font-color);
            text-decoration: none;
            width: fit-content;

            &:hover {
              border-bottom: var(--desktop-nav-column-category-hover-border);
              margin-bottom: 10px !important;
            }

            &:focus {
              box-sizing: border-box;
              outline: 1px dashed var(--focus-color);
            }
          }
        }
      }

      .close-button {
        align-items: center;
        background-color: var(--close-icon-background-color);
        color: var(--color-white);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 56px;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 51px;
        z-index: 10;

        &__icon {
          margin: var(--hmf-xxs) -10px -4px -10px !important;
          path {
            fill: var(--hmf-gray50);
          }
        }

        &:focus-visible {
          border: 1px dashed var(--color-white);
          box-sizing: border-box;
          outline: none;
        }

        &:hover {
          opacity: 0.75;
        }
      }
    }

    .menu-container {
      margin: 0 auto;
      overflow: hidden;
      position: absolute;
      width: 100%;
    }

    .skip-link {
      height: 1px;
      left: -1000px;
      position: absolute;
      width: 1px;

      &:focus {
        height: unset;
        left: unset;
        position: relative;
        width: fit-content;
      }
    }
  }
}

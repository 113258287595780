a.dsg-react-hyperlink {
  border-bottom: 1px solid var(--link-component-static-bottom-border);
  color: var(--primary-font-color);
  cursor: pointer;
  font-size: var(--link-component-font-size);
  font-weight: var(--link-component-font-weight);
  letter-spacing: var(--link-component-letter-spacing);
  line-height: var(--link-component-line-height);
  outline-color: transparent;
  outline-style: none;
  text-decoration: none;

  &:hover {
    border-bottom: 1px solid var(--link-component-hover-focus-active-bottom-border);
  }

  &:active {
    border-bottom: 1px solid var(--link-component-hover-focus-active-bottom-border);
    color: var(--link-component-alternate-font-color);
  }

  &:focus {
    border-bottom: 1px solid var(--link-component-hover-focus-active-bottom-border);
  }

  &:visited {
    border-bottom: 1px solid var(--link-component-visited-bottom-border);
    color: var(--link-component-alternate-font-color);
  }
}

a.dsg-react-inline-hyperlink {
  color: var(--primary-font-color);
  cursor: pointer;
  font-size: var(--link-component-inline-font-size);
  font-weight: normal;
  line-height: var(--link-component-inline-line-height);
  outline-color: transparent;
  outline-style: none;
  text-decoration: underline;

  &:hover {
    color: var(--link-component-inline-hover-focus-font-color);
  }

  &:active {
    color: var(--link-component-inline-active-visited-font-color);
  }

  &:focus {
    color: var(--link-component-inline-hover-focus-font-color);
  }

  &:visited {
    color: var(--link-component-inline-active-visited-font-color);
  }
}

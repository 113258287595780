@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-header-scope {
  .ticker-drawer-container {
    .hmf-modal-container {
      max-height: none !important;
      width: 100% !important;
    }

    .ticker-drawer {
      max-height: 100dvh; //The use of 100dvh is intentional for the dynamic browser height on Safari
      overflow-y: auto;
    }

    .ticker-drawer-header {
      position: sticky;
      top: 0;
      background-color: var(--hmf-gray50);
      height: 64px;
      flex-shrink: 0;
      .close-icon-container {
        background-color: var(--hmf-gray50);
        border: none;
        width: 50px;
        cursor: pointer;
        &:hover {
          background-color: var(--hmf-gray100);
        }
        &:focus-visible {
          background-color: var(--hmf-gray100);
          box-sizing: border-box;
          outline: 1px dashed var(--hmf-gray600);
        }

        svg {
          path {
            fill: black;
          }
        }
      }
      @include homefield.media-breakpoint-up('lg') {
        height: 108px;
      }
    }

    .ticker-drawer-body {
      .ticker-drawer-item-container {
        max-width: 1600px !important;
        gap: 48px;

        .ticker-drawer-item {
          flex: 1 1 100%;

          @include homefield.media-breakpoint-up('m') {
            max-width: 480px;
            flex: 1 1 calc((100% - 48px * (4 - 1)) / 4);
          }
        }
      }
    }

    .ticker-drawer-item-link {
      color: var(--hmf-gray900);
    }

    .ticker-drawer-item-link.focus-visible {
      outline: 1px dashed var(--hmf-gray600);
      outline-offset: 4px;
    }
  }
}

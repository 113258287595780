.calia-react-header-scope.hmf-sm-theme,
.calia-react-footer-scope.hmf-sm-theme {
  @extend .calia-react-header-scope;

  // GREEN
  --color-green-base-500: var(--hmf-primary500);
  --color-green-base-600: var(--hmf-primary600);
  --color-green-base-700: var(--hmf-primary700);
  --color-green-base-800: var(--hmf-primary800);

  // PRIMARY COLORS
  --primary-color: var(--hmf-primary500);
  --secondary-color: var(--hmf-gray500);
  --tertiary-color: var(--hmf-red500);

  // ACCENT COLORS
  --secondary-accent-color: var(--hmf-secondary400);

  // COMPONENT VARS
  --desktop-svg-fill-color: var(--color-green-base-500);
  --menu-icon-active-color: var(--hmf-gray100);

  // HEADER
  --header-dropdown-background-color: var(--hmf-primary700);
  --header-navbar-background-color: var(--hmf-gray100);
  --header-navbar-title-text-transform: uppercase;
  --header-links-font-family: "DSG Sans Black", sans-serif;

  // DROPDOWN MENU
  --dropdown-menu-border-radius: var(--hmf-sharp-radius);

  // FOOTER
  --footer-back-ground-color: #191f1c;
  --footer-links-font-family: 'DINPro-Light', sans-serif;
  --footer-links-underline-color: var(--hmf-gray50);
}

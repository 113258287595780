//aos-header.css
.aos-header-container {
  color: var(--color-white);

  .aos-modal.hmf-modal {
    .hmf-modal-container {
      min-height: unset;
      width: 400px;
    }
  }

  .aos-content {
    background-color: var(--hmf-gray800);

    .aos-button {
      height: 44px;
    }
  }
}

.calia-react-header-scope.hmf-g3-theme {
  .search-result-row {
    &:hover {
      color: var(--hmf-label-secondary-hover);
    }
    .calia-search {
      &-original-price {
        text-decoration: line-through;
      }

      &-thumbnail {
        max-height: 80px;
        max-width: 80px;
      }
    }

    a {
      overflow: hidden;

      div {
        float: left;
        text-align: left;
      }

      .calia-search-thumbnail img {
        height: 80px;
      }

      .product-information {
        h2 {
          color: var(--hmf-label-secondary-static);
        }

        .calia-search-price {
          color: var(--hmf-label-base);
        }
      }
    }
  }
}

.display-timer-wrapper {
  display: flex;

  .time-component {
    text-align: center;
  }

  .countdown-ada {
    clip: rect(0, 0, 0, 0);
    height: 0;
    position: absolute;
    width: 0;
  }
}

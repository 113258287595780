.slickSlider-customDot {
  background-color: var(--hmf-tertiary200);
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.slick-dots {
  li {
    margin: 0;
  }

  .slick-active {
    .slickSlider-customDot {
      background-color: var(--quick-view-slider-dots);
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;
@use 'src/styles/_legacy-breakpoints.scss' as legacy-breakpoints;

.bottomFooterLinks-container {
  background-color: var(--hmf-background-base-subtle);

  &__content-wrapper {
    margin: 0 auto;
    max-width: 1600px;
    @media (legacy-breakpoints.$mobile) {
      text-align: center;
    }
  }

  &__copyright {
    color: var(--hmf-label-base);
  }
  &__links-wrapper {
    display: flex;
    @media (legacy-breakpoints.$mobile) {
      flex-wrap: wrap;
    }
    &--links {
      color: var(--hmf-label-base);
      width: 50%;
      display: flex;
      @include homefield.media-breakpoint-up('m') {
        width: 20%;
      }

      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &__feedback-row {
    &--button {
      background-color: var(--secondary-color);
      border: none;
      color: var(--hmf-label-base);
      padding: 12px;
      padding-left: 16px;
      padding-right: 16px;
      text-decoration: none;

      &:hover {
        background: var(--drop-down-menu-button-onhover-background-color);
        border: var(--footer-signup-email-border-hover);
      }

      &:active {
        background: var(--hmf-primary100);
      }

      &:focus {
        background: var(--drop-down-menu-button-onhover-background-color);
        outline: none;
        outline-style: none;
      }
    }

    &--link {
      color: var(--hmf-label-base);
      text-decoration: none;

      &:hover {
        color: var(--hmf-label-base);
        text-decoration: none;
      }
    }
  }
}

.lists-panel {
  &-content {
    height: 100vh !important;
    width: 100%;
  }

  &-wrapper {
    height: 100vh !important;
  }

  &-header {
    background-color: var(--hmf-gray100);
    color: var(--hmf-gray900);

    a {
      margin: -2px;
    }
  }

  &.hmf-modal
    .hmf-modal-container
    .hmf-modal-container-wrapper
    .hmf-modal-container-close.hmf-close-primary {
    background: var(--hmf-gray900);
    height: var(--hmf-xl);
    margin: 0 !important;

    svg {
      margin-top: var(--hmf-xs);

      path {
        fill: var(--hmf-gray50);
      }
    }
  }

  &-button {
    width: 100%;
  }

  &-create-button {
    border: 1px solid var(--hmf-border-inverse) !important;
    width: 100%;
  }

  &-create-button:hover {
    border: 1px solid var(--hmf-border-inverse) !important;
  }

  &-body {
    .lists-panel-link {
      text-decoration: underline;
    }
  }
  .delete-list-form {
    width: 100%;
  }

  .lists-container {
    height: calc(100vh - var(--hmf-m) * 2);
    position: relative;
    width: 100%;
  }

  .lists-content {
    width: calc(100% - var(--hmf-m) * 2);
    height: calc(100vh - 280px);
    overflow-y: auto;
  }

  .lists-content-limit {
    height: calc(100vh - 380px);
  }

  .list-line {
    cursor: pointer;
  }

  .list-input {
    background-color: transparent;
    border: none;
    margin: 0;
    color: var(--hmf-label-base);

    &.disabled-text {
      color: var(--hmf-gray300);
      cursor: no-drop;
    }

    .list-items-limit {
      color: var(--hmf-red600);
    }
  }

  .list-input:active,
  .list-input:focus {
    border: none;
  }

  hr {
    border: 0;
    border-bottom: 1px solid var(--hmf-border-base);
  }

  .list-header {
    box-shadow: 0px 2px 2px -2px rgba(26, 26, 26, 0.2);
  }

  .list-footer {
    background: var(--hmf-gray50);
    bottom: 0;
    box-shadow: 0px -2px 8px -2px rgba(26, 26, 26, 0.2);
    left: 0;
    right: 0;
    z-index: 2;
  }

  .form-row-title {
    color: var(--hmf-label-base);
  }

  .form-row-subtitle {
    color: var(--hmf-label-base-subtle);
  }

  .list-form-row {
    width: 100%;
  }

  .limit-alert-banner-icon {
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.trending-search-chip {
  height: 32px;
  background-color: var(--hmf-fill-secondary-static-subtle);
  border: 1px solid var(--hmf-border-base-subtle);
  border-radius: var(--hmf-round-radius);
  color: var(--hmf-label-base);
  cursor: pointer;
  box-shadow: none;

  span {
    height: 100%;
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.topNavBar-container {
  background-color: var(--desktop-nav-background-color) !important;
  &__content-wrapper {
    display: none;
    margin: 0 auto;
    max-width: 1600px;
    @include homefield.media-breakpoint-up('lg') {
      display: flex;
    }
  }
  &__text-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
    &--links {
      color: var(--primary-color);
      text-decoration: none;
      &:hover {
        color: var(--primary-color);
        font-weight: bold !important;
        text-decoration: underline;
        text-decoration-color: var(--top-navbar-links-hover-text-decoration-color);
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
      }

      &:visited {
        color: var(--primary-color);
      }

      // avoid shifting of layout when links are bolded
      .hidden-item {
        height: 0;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none;
        visibility: hidden;
      }
      &:focus-visible {
        box-sizing: border-box;
        outline: 1px dashed var(--focus-color);
      }
    }
  }

  .border-left {
    border-left: 1px solid var(--hmf-gray400);
  }

  .bold-text {
    &:hover {
      font-weight: normal !important;
      letter-spacing: 0.8px;
    }
  }

  .topNavBar-container__text-container {
    min-width: 620px;
  }
}

.enterprise-brand-links {
  cursor: pointer;
  gap: 15px;

  .header2-pl-other-brands-logo {
    width: 115px;
    height: 13px;

    &.golf_galaxy {
      height: 19px;
      width: 41px;
    }

    &.vrst {
      width: 55px;
    }

    &.calia {
      width: 72px;
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;
$cart-count-icon-offset-mobile: -6px;
$cart-count-icon-offset-desktop: 1px;

.calia-react-header-scope {
  .cart-container-header {
    // increase tap area for the icons to 44x44
    padding: 10px;
    @include homefield.media-breakpoint-up('md') {
      height: 56px;
    }
    &:focus-visible {
      background-color: var(--header-icon-focused-color);
      border: 1px dashed var(--header-icon-border-focused);
      border-radius: var(--hmf-soft-radius);
      outline: none;
    }
    &:hover {
      background-color: var(--header-icon-hover-color);
      border-radius: var(--hmf-soft-radius);
    }
    &:active {
      background-color: var(--header-icon-active-color);
      border-radius: var(--hmf-soft-radius);
    }

    .cart-link-content {
      color: var(--primary-color);
    }

    .cart-icon {
      path {
        fill: var(--primary-color);
      }
    }

    .cart-item-count {
      @include homefield.media-breakpoint-up('md') {
        top: $cart-count-icon-offset-desktop;
      }
      left: var(--hmf-s);
      top: $cart-count-icon-offset-mobile;
      background-color: var(--oval-count-background-color);
      height: var(--hmf-s);
      width: var(--hmf-s);
      color: var(--oval-count-font-color);
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.button-container {
  flex: 1;
  height: 44px;

  &.quickview-viewCart {
    flex: 0 0 100%;
  }

  @include homefield.media-breakpoint-up('md') {
    height: 56px;

    &.quickview-viewCart {
      flex: 1;
    }
  }
}

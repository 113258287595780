/*! purgecss start ignore */
@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.consolidated-nav-container {
  border-bottom: 1px solid var(--hmf-border-base-subtle);
  border-top: 1px solid var(--hmf-border-base-subtle);
  max-width: 100vw;
}

.consolidated-nav {
  height: 48px;
  max-width: 1600px;

  .consolidated-positioner-div-for-popover {
    left: 70px;
    top: 50px;
  }

  .left-gradient {
    background: linear-gradient(
      to left,
      var(--hmf-fill-secondary-static) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .all-categories-button {
    svg path {
      fill: var(--hmf-secondary900);
    }
  }

  .consolidated-header-right-gradient {
    background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
    height: 44px;
    left: 12px;
    white-space: nowrap;
    width: 12px;
    @include homefield.media-breakpoint-up('lg') {
      background: none;
      height: none;
      left: none;
      white-space: none;
      width: none;
    }
  }

  .consolidated-header-link {
    &.scrollable {
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
    }

    .consolidated-header-link-title,
    .consolidated-header-button-title {
      color: var(--hmf-label-base);
      cursor: pointer;
      height: 48px;
      text-decoration: none;
      white-space: nowrap;
    }

    .consolidated-header-button-title {
      background-color: transparent;
      border: none;
    }

    .consolidated-header-empty-space {
      min-width: 20px;
      white-space: nowrap;
    }
  }

  .header-same-day-delivery-button {
    padding-bottom: none;
  }

  .consolidated-header-arrow-icon {
    height: 100%;
  }

  .consolidated-header-link-label {
    svg path {
      fill: var(--hmf-secondary500);
    }
  }

  .all-categories-button {
    cursor: pointer;
    white-space: nowrap;
    background: transparent;
    border: none;
  }
  .consolidated-trending-search > div:nth-last-child(-n + 3) {
    @include homefield.media-breakpoint-up('lg') {
      display: none;
    }
  }
}

/*! purgecss end ignore */

@import './Swatch.scss';

.attribute-groups {
  &:first-child {
    margin-top: 0px;
  }
}

.attribute {
  margin-right: -8px;

  .value {
    background: var(--hmf-background-base);
    border: 1px solid var(--hmf-border-secondary-subtle-static);
    box-sizing: border-box;
    cursor: pointer;
    color: var(--hmf-label-base);

    &:hover {
      &:not([aria-disabled='true']) {
        border: 1px solid var(--hmf-border-inverse-subtle);
        background: var(--hmf-background-base);
      }
    }

    &:focus {
      outline: none;
    }
  }

  .disabled {
    background: var(--hmf-background-base-subtle);
    border-color: var(--hmf-background-base-subtle);
    color: var(--hmf-fill-disabled);

    &::before {
      border-top: 1px solid var(--hmf-border-secondary-subtle-active);
      box-shadow: 0 0 0 1px var(--hmf-fill-base);
      content: '';
      left: 50%;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 32px;
    }
  }

  .selected,
  .selected:hover:not([aria-disabled='true']) {
    background-color: var(--hmf-fill-inverse);
    color: var(--hmf-fill-base);

    &.disabled::before {
      border: none;
    }
  }

  &-error-message svg {
    path {
      fill: var(--hmf-fill-negative);
    }
  }
}

.view-all-colors p {
  color: var(--hmf-label-base);
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 14px;
  margin-bottom: 4px;
  margin-top: 23px;
  text-align: center;
  text-transform: uppercase;
}

.view-all-colors:hover p {
  border-bottom: 2px solid var(--quick-view-shipping-border-bottom-color);
  margin-bottom: 2px;
}

.view-all-colors:active p {
  border-bottom: 4px solid var(--quick-view-shipping-border-bottom-color);
  margin-bottom: 0px;
}

.view-all-colors:focus p {
  border: 1px dashed var(--theme-focus-outline-color);
  margin-bottom: 3px;
  margin-top: 1px;
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;
@use 'src/styles/_legacy-breakpoints.scss' as legacy-breakpoints;

.calia-react-header-scope {
  .header-ticker-minimum {
    min-height: 64px;

    @include homefield.media-breakpoint-up('lg') {
      min-height: 48px;
    }
  }
}

.rh-ticker-carousel-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  .rh-ticker-carousel {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    width: 60vw;

    @media screen and (max-width: legacy-breakpoints.$mobile-width) {
      align-self: center;
      width: 95vw;
    }

    svg {
      path {
        fill: white;
      }
    }

    button {
      -webkit-appearance: none;
      background: none;
      border: none;
      border-radius: 0;
      cursor: pointer;
      height: 29px;
      padding: 0;
      width: 24px;
      &:focus-visible {
        outline: 1px dashed var(--hmf-gray50);
        outline-offset: 5px;
      }
    }

    .rh-ticker-carousel-control--spacer {
      height: 1px;
      width: 24px;
    }

    .rh-ticker-carousel-track {
      display: block;
      overflow: hidden;

      .rh-ticker-carousel-inner {
        align-self: center;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        width: 6000px;

        .rh-ticker-carousel-item {
          display: flex;
          width: 40vw;

          @media screen and (max-width: legacy-breakpoints.$mobile-width) {
            width: 70vw;
          }

          .rh-ticker-carousel-content-section {
            margin: auto;
            padding: 8px;
            width: 100%;

            a {
              cursor: pointer;
              display: flex;
              flex-direction: column;
              padding-left: 1vw;
              text-decoration: none;
              &:focus-visible {
                outline: 1px dashed var(--hmf-gray50);
              }

              .rh-ticker-carousel-top-section {
                font-family: var(--ticker-font-family);
                font-size: var(--ticker-font-size);
                font-style: var(--ticker-font-style);
                font-weight: var(--ticker-font-weight);
                letter-spacing: var(--ticker-letter-spacing);
                line-height: var(--ticker-line-height);
              }

              .rh-ticker-carousel-bottom-section {
                font-family: var(--ticker-link-font-family);
                font-size: var(--ticker-link-font-size);
                font-style: var(--ticker-link-font-style);
                font-weight: var(--ticker-link-font-weight);
                letter-spacing: var(--ticker-link-letter-spacing);
                line-height: var(--ticker-link-line-height);

                .rh-ticker-carousel-link {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*! purgecss start ignore */

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.myaccount-drawer-container {
  .hmf-modal-container {
    width: calc(100% - 18px) !important;
    @include homefield.media-breakpoint-up('sm') {
      max-width: 400px !important;
    }
  }

  .my-account-drawer-content-container {
    background-color: var(--hmf-background-base);
    width: 100% !important;
  }

  .my-account-drawer-header {
    border-bottom: 1px solid var(--hmf-border-base-subtle);

    .title {
      min-height: 48px;
    }

    .close-icon-container {
      cursor: pointer;
      &:hover {
        background-color: var(--hmf-gray100);
      }
      &:focus-visible {
        background-color: var(--hmf-gray100);
        box-sizing: border-box;
        outline: 1px dashed var(--hmf-gray600);
      }
    }
  }

  .my-account-drawer-body {
    .my-account-signed-in {
      .my-account-menu-item {
        color: var(--hmf-label-base);
        cursor: pointer;
        text-decoration: none;
        &:active {
          background: var(--hmf-fill-secondary-active);
          border-radius: var(--hmf-softer-radius);
        }
        &:hover {
          background-color: var(--hmf-fill-secondary-hover);
          border-radius: var(--hmf-softer-radius);
        }
        &:focus-visible {
          background-color: var(--hmf-fill-secondary-hover);
          border: 1px dashed var(--hmf-border-focused);
          border-radius: var(--hmf-softer-radius);
          outline: none;
        }
      }
    }
    .my-account-signed-out {
      .sub-header-text {
        color: var(--hmf-label-base-subtle);
      }
      .divider-container {
        .divider-text {
          color: var(--hmf-label-base-subtle);
          width: 42%;
        }
        .hmf-divider {
          width: 31%;
        }
      }
      .policy-container {
        a {
          color: var(--hmf-label-base-subtle);
          font-weight: var(--font-primary-bold-weight);
          text-decoration-color: var(--hmf-label-base-subtle);
        }
      }
    }
  }

  .sign-in-button-container {
    .sign-in-button {
      width: 100%;
    }
    .sign-in-button-common {
      background: var(--hmf-fill-secondary-static);
      border: 1px solid var(--hmf-border-inverse);
      border-radius: var(--hmf-soft-radius);
      cursor: pointer;
      height: 44px;
      width: 100%;

      &:hover {
        background-color: var(--hmf-button-secondary-hover-background-color);
        border: 2px solid var(--hmf-button-secondary-hover-border-color);
      }
      &:focus {
        outline: 1px dashed var(--hmf-gray600);
        outline-offset: 4px;
      }
    }
  }
}

/*! purgecss end ignore */

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.new-taxo-menu-dialog {
  cursor: default;
  .hmf-modal-container {
    display: none !important;
    height: 127px !important;
    width: 290px !important;

    @include homefield.media-breakpoint-up('lg') {
      display: flex !important;
    }

    &::before,
    &::after {
      border-bottom: 20px solid var(--hmf-gray50);
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      content: '';
      left: 10px;
      position: absolute;
      top: -20px;
    }
  }
  svg {
    cursor: pointer;
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-header-scope.hmf-g3-theme {
  .rc-search {
    // make sure we fit a minimum tap target
    min-height: 56px;

    @include homefield.media-breakpoint-up('md') {
      max-width: 320px;
    }

    @include homefield.media-breakpoint-up('xlg') {
      max-width: 360px;
    }
  }

  .calia-search {
    @include homefield.media-breakpoint-up('md') {
      border-left: 1px solid var(--hmf-border-base);
    }

    .search-forward-action {
      path {
        fill: var(--hmf-label-base);
      }
    }

    .forward-logo {
      -webkit-appearance: none;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      cursor: pointer;
    }

    &-results-box {
      background-color: var(--hmf-background-base-subtle);
      position: absolute;
      left: 0;
      right: 0;
      // these are to position the suggestion just below the search bar and to make it full width of the screen
      top: 60px;
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      z-index: 30;

      @include homefield.media-breakpoint-up('md') {
        margin-left: 0;
        top: 75px;
        width: 100%;
        border: 1px solid var(--search-results-box-border-color);
        border-top: 0;
      }
    }

    &-price {
      color: var(--hmf-primary900);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 18px;
    }

    &-suggestion {
      &-result {
        color: var(--hmf-label-secondary-static);

        &:hover {
          color: var(--hmf-label-secondary-hover);
        }
      }
    }

    &-view-all-results {
      color: var(--font-color-primary);
    }
  }

  .clear-search-input {
    height: 56px;
    svg {
      min-height: 24px;
      min-width: 24px;
    }

    input[type='search'] {
      -webkit-appearance: none;
      appearance: none;
      background: var(--hmf-gray50);
      border: none;
      height: 100%;

      &::placeholder {
        color: var(--hmf-label-secondary-subtle-static);
      }

      &:hover {
        color: var(--hmf-label-secondary-subtle-hover);

        &::placeholder {
          color: var(--hmf-label-secondary-subtle-hover);
        }
      }

      &:focus {
        border-bottom: 2px solid var(--hmf-border-primary-hover);
        outline: none;
      }

      // Hide default search icon
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }

    input::-ms-clear {
      display: none;
    }

    .clear-search-btn {
      background-color: transparent;
      border: none;
      cursor: pointer;

      span {
        border-bottom: 1px solid var(--hmf-label-secondary-subtle-static);
      }
    }

    .clear-input {
      visibility: hidden;
    }
  }
}

.calia-react-header-scope.hmf-pl-theme,
.calia-react-footer-scope.hmf-pl-theme {
  @extend .calia-react-header-scope;

  // OVERRIDES
  --header-icon-hover-color: var(--hmf-fill-primary-hover);
  --header-icon-focused-color: var(--hmf-fill-primary-static);
  --header-icon-border-focused: var(--hmf-border-inverse-focused);
  --header-icon-active-color: var(--hmf-fill-primary-active);
  --focus-color: var(--hmf-secondary600);
  --search-bar-focus-border-color: var(--hmf-primary800);
  --font-color-primary: var(--hmf-gray50);
  --common-focus-color: var(--hmf-secondary600);
  --primary-accent-color: var(--hmf-tertiary100);
  --tertiary-accent-color: var(--hmf-primary600);
  --close-icon-background-color: var(--hmf-primary800);
  --header-background-color: var(--hmf-primary800);
  --header-border-color: var(--hmf-primary600);
  --header-my-account-icon-color: var(--hmf-primary50);
  --header-cart-icon-height: 24px;
  --header-cart-icon-width: 24px;
  --header-navbar-links-text-decoration-color: var(--hmf-accent100);
  --header-search-bar-sticky-background-color: var(--hmf-tertiary100);
  --header-sticky-background-color: var(--hmf-tertiary100);
  --drop-down-menu-button-background-color: var(--hmf-primary800);
  --drop-down-menu-button-text-color: var(--hmf-gray50);
  --desktop-nav-background-color: var(--hmf-primary900);
  --desktop-nav-link-visited-color: inherit;
  --nav-menu-background-color: var(--hmf-tertiary100);
  --mobile-nav-accent-background-color: var(--hmf-tertiary100);
  --mobile-nav-border-spacer-styling: none;
  --nav-box-shadow-color: var(--hmf-tertiary100);
  --search-bar-background-color: var(--hmf-tertiary100);
  --search-bar-border-color: var(--hmf-primary100);
  --search-bar-font-color: var(--hmf-primary700);
  --search-bar-hover-border-color: var(--hmf-primary300);
  --search-results-box-background-color: var(--hmf-tertiary100);
  --search-results-font-color: var(--hmf-primary800);
  --search-results-header-color: var(--hmf-primary600);
  --search-bar-icon-color: var(--hmf-primary700);
  --sayt-results-font-color: var(--hmf-primary800);
  --footer-links-heading-color: var(--hmf-tertiary200);
  --footer-logo-width: 275px;
  --quick-view-alert-warn-background-color: var(--hmf-primary100);
  --quick-view-alert-warn-border-color: var(--hmf-tertiary300);
  --header-my-account-signin-fill-color: var(--hmf-tertiary50);
  --header-trending-search-heading: var(--hmf-accent100);

  // TYPOGRAPHY
  --font-family-primary: 'Graphik', sans-serif;
  --font-family-secondary: 'Graphik Wide';
  --primary-font-family: 'Graphik', sans-serif;
  --primary-font: 'Graphik', sans-serif;

  // PRIMARY COLORS
  --primary-color: var(--hmf-gray50);
  --secondary-color: var(--hmf-primary800);
  --tertiary-color: var(--hmf-accent100);
  --theme-focus-outline-color: var(--hmf-secondary600);

  // COMPONENT VARS
  --accordion-flag-background-color: var(--hmf-primary800);
  --aos-header-current-offers-color: var(--hmf-primary800);
  --desktop-svg-fill-color: var(--tertiary-color);

  // DROPDOWN MENU
  --drop-down-menu-button-onhover-background-color: var(--hmf-primary600);

  // NAVIGATION
  --desktop-nav-column-category-hover-border: 2px solid var(--hmf-primary800);
  --desktop-nav-link-hover-border-color: var(--hmf-primary800);
  --desktop-nav-link-hover-color: var(--hmf-primary800);
  --desktop-nav-title-border-color: var(--hmf-accent100);
  --nav-menu-font-color: var(--hmf-primary800);
  --nav-menu-hover-color: var(--secondary-color);

  // TOP NAVBAR
  --top-navbar-links-hover-text-decoration-color: var(--hmf-accent100);

  // CART
  --cart-icon-color: var(--hmf-gray50);
  --oval-count-background-color: var(--hmf-accent100);
  --oval-count-font-color: var(--hmf-primary800);

  // TICKER
  --ticker-font-family: var(--primary-font);
  --ticker-link-font-family: var(--primary-font);

  // FOOTER
  --footer-background-color: var(--hmf-primary800);
  --footer-desktop-nav-title-border-color: var(--hmf-accent100);
  --footer-links-text-decoration-color: var(--hmf-accent100);
  --footer-primary-color: var(--hmf-gray50);
  --footer-secondary-color: var(--secondary-color);
  --footer-social-links-color: var(--tertiary-color);

  // EMAIL SIGNUP
  --footer-email-signup-focused-border-color: var(--secondary-color);
  --footer-email-signup-input-border: 1px solid var(--hmf-primary500);
  --footer-email-signup-signup-success-color: var(--hmf-primary500);
  --footer-signup-email-border-hover: none;
  --footer-signup-email-border: none;

  // QUICKVIEW QUANTITY FONT STYLES
  --quick-view-close-button-color: var(--hmf-fill-inverse);
  --quick-view-icon-color: var(--hmf-primary800);
  --quick-view-price-background-color: $primary-font-color;
  --quick-view-shipping-border-bottom-color: var(--hmf-primary800);
  --quick-view-slider-dots: var(--hmf-tertiary900);
  --quick-view-product-details-font-color: var(--hmf-primary700);

  // STORE PICKUP
  --store-pickup-selected-store-color: var(--hmf-secondary500);

  // LINK COMPONENT
  --link-component-alternate-font-color: var(--primary-color);
  --link-component-font-size: var(--body-font-size-s);
  --link-component-font-weight: bold;
  --link-component-hover-focus-active-bottom-border: var(--hmf-primary800);
  --link-component-inline-active-visited-font-color: var(--hmf-tertiary400);
  --link-component-inline-font-size: var(--body-font-size-s);
  --link-component-inline-hover-focus-font-color: var(--hmf-primary800);
  --link-component-inline-line-height: 20px;
  --link-component-letter-spacing: 0.5px;
  --link-component-line-height: 20px;
  --link-component-static-bottom-border: var(--primary-color);
  --link-component-visited-bottom-border: var(--primary-color);

  // PL SPECIFIC - BUTTON COMPONENT - COMMON STYLES
  --button-font-family: 'Graphik Wide';
  --button-font-size: 16px;
  --button-font-style: normal;
  --button-font-weight: bold;
  --button-height: 52px;
  --button-letter-spacing: 1.5px;
  --button-line-height: 16px;
  --button-text-transform: uppercase;
  --button-width: 100%;

  // PL SPECIFIC - BUTTON COMPONENT - MARKETING STYLES

  // MARKETING STATIC
  --button-marketing-static-bg-color: var(--hmf-accent100);
  --button-marketing-static-border: 3px solid var(--hmf-accent100);
  --button-marketing-static-font-color: var(--hmf-primary800);
  --button-marketing-static-radius: 0px;

  // MARKETING HOVER
  --button-marketing-hover-bg-color: var(--hmf-accent50);
  --button-marketing-hover-border: 3px solid var(--hmf-accent50);
  --button-marketing-hover-font-color: var(--hmf-primary800);
  --button-marketing-hover-radius: 0px;

  // MARKETING ACTIVE
  --button-marketing-active-bg-color: var(--hmf-accent200);
  --button-marketing-active-border: 3px solid var(--hmf-accent200);
  --button-marketing-active-font-color: var(--hmf-primary800);
  --button-marketing-active-radius: 0px;

  // MARKETING INACTIVE
  --button-marketing-inactive-bg-color: var(--hmf-tertiary100);
  --button-marketing-inactive-border: 3px solid var(--hmf-tertiary100);
  --button-marketing-inactive-font-color: var(--hmf-tertiary300);
  --button-marketing-inactive-radius: 0px;

  // MARKETING FOCUSED
  --button-marketing-focus-bg-color: var(--hmf-accent100);
  --button-marketing-focus-border: 3px solid var(--hmf-accent100);
  --button-marketing-focus-font-color: var(--hmf-primary800);
  --button-marketing-focus-outline: 1px dashed var(--hmf-secondary600);
  --button-marketing-focus-radius: 0px;

  // PL SPECIFIC - BUTTON COMPONENT - PRIMARY

  // PRIMARY STATIC
  --button-primary-static-bg-color: var(--hmf-primary800);
  --button-primary-static-border: none;
  --button-primary-static-font-color: var(--color-white);
  --button-primary-static-radius: 0px;

  // PRIMARY HOVER
  --button-primary-hover-bg-color: var(--hmf-primary800);
  --button-primary-hover-border: none;
  --button-primary-hover-font-color: var(--color-white);
  --button-primary-hover-radius: 0px;

  // PRIMARY ACTIVE
  --button-primary-active-bg-color: var(--hmf-primary900);
  --button-primary-active-border: none;
  --button-primary-active-font-color: var(--color-white);
  --button-primary-active-radius: 0px;

  // PRIMARY INACTIVE
  --button-primary-inactive-bg-color: var(--hmf-primary200);
  --button-primary-inactive-border: none;
  --button-primary-inactive-font-color: var(--hmf-primary400);
  --button-primary-inactive-radius: 0px;

  // PRIMARY FOCUSED
  --button-primary-focus-bg-color: var(--hmf-primary800);
  --button-primary-focus-border: none;
  --button-primary-focus-font-color: var(--color-white);
  --button-primary-focus-outline: 1px dashed var(--hmf-secondary600);
  --button-primary-focus-radius: 0px;

  // PL SPECIFIC - BUTTON COMPONENT - SECONDARY

  // SECONDARY STATIC
  --button-secondary-static-bg-color: var(--color-white);
  --button-secondary-static-border: 3px solid var(--hmf-primary800);
  --button-secondary-static-font-color: var(--hmf-primary800);
  --button-secondary-static-radius: 0px;

  // SECONDARY HOVER
  --button-secondary-hover-bg-color: var(--color-white);
  --button-secondary-hover-border: 1px solid var(--hmf-primary800);
  --button-secondary-hover-font-color: var(--hmf-primary800);
  --button-secondary-hover-radius: 0px;

  // SECONDARY ACTIVE
  --button-secondary-active-bg-color: var(--hmf-primary800);
  --button-secondary-active-border: 2px solid var(--hmf-primary800);
  --button-secondary-active-font-color: var(--color-white);
  --button-secondary-active-radius: 0px;

  // SECONDARY INACTIVE
  --button-secondary-inactive-bg-color: var(--color-white);
  --button-secondary-inactive-border: 2px solid var(--hmf-tertiary100);
  --button-secondary-inactive-font-color: var(--hmf-tertiary300);
  --button-secondary-inactive-radius: 0px;

  // SECONDARY FOCUSED
  --button-secondary-focus-bg-color: var(--color-white);
  --button-secondary-focus-border: 2px solid var(--hmf-primary800);
  --button-secondary-focus-font-color: var(--hmf-primary800);
  --button-secondary-focus-outline: 1px dashed var(--hmf-secondary600);
  --button-secondary-focus-radius: 0px;

  // PL SPECIFIC - BUTTON COMPONENT - TERTIARY

  // TERTIARY STATIC
  --button-tertiary-static-bg-color: var(--color-white);
  --button-tertiary-static-border: none;
  --button-tertiary-static-font-color: var(--hmf-primary800);
  --button-tertiary-static-radius: 0px;

  // TERTIARY HOVER
  --button-tertiary-hover-bg-color: var(--color-white);
  --button-tertiary-hover-border: none;
  --button-tertiary-hover-font-color: var(--hmf-primary800);
  --button-tertiary-hover-label-underline: 1px solid var(--hmf-primary300);
  --button-tertiary-hover-radius: 0px;

  // TERTIARY ACTIVE
  --button-tertiary-active-bg-color: var(--color-white);
  --button-tertiary-active-border: none;
  --button-tertiary-active-font-color: var(--hmf-primary800);
  --button-tertiary-active-label-underline: 2px solid var(--hmf-primary500);
  --button-tertiary-active-radius: 0px;

  // TERTIARY INACTIVE
  --button-tertiary-inactive-bg-color: var(--color-white);
  --button-tertiary-inactive-border: none;
  --button-tertiary-inactive-font-color: var(--hmf-primary800);
  --button-tertiary-inactive-radius: 0px;

  // TERTIARY FOCUSED
  --button-tertiary-focus-bg-color: var(--color-white);
  --button-tertiary-focus-border: none;
  --button-tertiary-focus-font-color: var(--hmf-primary800);
  --button-tertiary-focus-outline: 1px dashed var(--hmf-secondary600);
  --button-tertiary-focus-radius: 0px;

  // AOS
  --aos-current-offers-bg-color: var(--hmf-accent100);
}

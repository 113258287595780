.calia-react-header-scope.hmf-g3-theme,
.calia-react-footer-scope.hmf-g3-theme {
  @extend .calia-react-header-scope;

  // OVERRIDES
  --hamburger-menu-hover-color: var(--hmf-fill-secondary-hover);
  --focus-color: var(--hmf-gray200);
  --drop-down-menu-button-background-color: var(--hmf-secondary800);
  --drop-down-menu-button-text-color: var(--hmf-gray50);
  --ticker-background-color: var(--hmf-secondary800);
  --ticker-font-size: var(--header-font-size-xs);
  --ticker-font-weight: 400;
  --ticker-letter-spacing: 1px;
  --ticker-line-height: var(--header-line-height-xs);
  --ticker-link-font-size: var(--subhead-font-size-s);
  --ticker-link-font-weight: 400;
  --ticker-link-letter-spacing: 1px;
  --ticker-link-line-height: var(--header-line-height-xs);
  --header-navbar-nav-icon-fill-color: var(--hmf-gray900);
  --search-bar-icon-color: var(--primary-color);

  // TYPOGRAPHY
  --font-family-display: 'DSG Sans', san-serif;
  --font-family-primary: 'Roboto', san-serif;
  --font-family-secondary: 'Helvetica';
  --special-case-font-weight: 500;
  --special-case-font: 'Roboto', san-serif;

  // COMMON COLORS
  --text-onhover-color: var(--hmf-primary700);

  // PRIMARY COLORS
  --primary-color: var(--hmf-primary500);
  --theme-focus-outline-color: var(--hmf-secondary800);

  // COMPONENT VARS
  --columns-background: var(--hmf-background-base);

  // HEADER
  --header-logo-background-color: var(--hmf-gray50);

  // TOP NAVBAR
  --top-mobile-background-color: var(--hmf-primary100);
  --top-navbar-background-color: var(--hmf-fill-base-subtle);

  // TOP NAVBAR LEFT TITLE
  --top-navbar-left-line-height: var(--subhead-line-height-m);
  --top-navbar-left-text-transform: none;

  // SLIDE IN NAV
  --mobile-menu-border: 0.5px solid var(--hmf-gray200);
  --mobile-menu-font-size: 16px;
  --mobile-menu-letter-spacing: 2px;
  --mobile-menu-line-height: 22.4px;

  // HEADER NAVBAR
  --header-navbar-title-font-family: var(--font-primary);

  // DROPDOWN MENU
  --drop-down-menu-button-onActive-background-color: var(--hmf-secondary800);
  --drop-down-menu-button-onfocus-background-color: var(--hmf-secondary800);
  --drop-down-menu-button-onfocus-border: 1px dashed var(--hmf-tertiary600);
  --drop-down-menu-button-onhover-background-color: var(--hmf-tertiary700);

  // CATEGORY SHOP
  --category-shop-all-font-family: var(--font-primary);
  --category-shop-all-font-size: var(--legal-font-size-xs);
  --category-shop-all-font-style: normal;
  --category-shop-all-font-weight: normal;
  --category-shop-all-letter-spacing: 0.5px;
  --category-shop-all-line-height: var(--legal-line-height-s);

  // MOBILE OUR STORY AND FIND A STORE
  --mobile-story-find-store-font-family: var(--font-primary);
  --mobile-story-find-store-font-size: var(--subhead-font-size-s);
  --mobile-story-find-store-font-style: normal;
  --mobile-story-find-store-font-weight: 600;
  --mobile-story-find-store-letter-spacing: 0.5px;
  --mobile-story-find-store-line-height: var(--subhead-line-height-m);

  // SEARCH
  --search-results-box-border-color: var(--hmf-gray300);
  --search-view-all-result-border-bottom: 1px solid var(--hmf-secondary900);

  // TOP PRODUCT
  --top-product-price-font-size: var(--subhead-font-size-s);
  --top-product-price-letter-spacing: 0.5px;
  --top-product-price-line-height: var(--subhead-line-height-m);

  // TOP PRODUCT TITLE
  --top-product-title-font-family: var(--font-primary);
  --top-product-title-font-size: var(--subhead-font-size-s);
  --top-product-title-font-style: normal;
  --top-product-title-font-weight: 600;
  --top-product-title-letter-spacing: 0.5px;
  --top-product-title-line-height: var(--subhead-line-height-m);
  --top-product-title-text-transform: none;

  // TOP PRODUCT PRICE
  --top-product-discount-price-text-color: var(--hmf-gray900);
  --top-product-price-font-family: var(--font-primary);
  --top-product-price-font-style: normal;
  --top-product-was-price-text-color: var(--hmf-tertiary600);

  // TICKER
  --ticker-font-family: var(--font-primary);
  --ticker-link-font-family: var(--font-primary);

  // FOOTER
  --footer-back-ground-color: var(--hmf-secondary800);
  --footer-border-top-right-radius: 50px;
  --footer-details-background: var(--hmf-accent50);
  --footer-details-color: var(--hmf-label-base);
  --footer-text-color: var(--hmf-gray50);

  // EMAIL SIGNUP
  --footer-email-signup-focused-border-color: var(--hmf-secondary400);
  --footer-signup-email-border-hover: 1px solid var(--hmf-primary500);
  --footer-signup-email-border: 3px solid var(--hmf-primary500);

  // FOOTER SITE LINKS
  --footer-site-link-font-family: var(--font-primary);
  --footer-site-link-font-size: var(--legal-font-size-s);
  --footer-site-link-font-style: normal;
  --footer-site-link-font-weight: normal;
  --footer-site-link-letter-spacing: 0.5px;
  --footer-site-link-line-height: var(--legal-line-height-s);
  --footer-site-link-text-transform: none;

  // FOOTER PRO DISCLAM
  --footer-pro-disclam-font-size: var(--legal-font-size-xs);
  --footer-pro-disclam-font-style: normal;
  --footer-pro-disclam-font-weight: normal;
  --footer-pro-disclam-letter-spacing: 0.5px;
  --footer-pro-disclam-line-height: var(--legal-line-height-s);
  --footer-pro-disclam-text-transform: none;

  // FOOTER MOBILE
  --mobile-footer-back-ground-color: var(--hmf-tertiary50);
  --mobile-footer-text-color-bottom: var(--hmf-gray900);

  // SCORE MORE
  --score-more-background-color: var(--hmf-primary500);

  // QUICKVIEW QUANTITY FONT STYLES
  --quick-view-close-button-color: var(--hmf-fill-inverse);
  --quick-view-final-price-color: var(--hmf-gray900);
  --quick-view-price-background-color: var(--hmf-red900);
  --quick-view-shipping-border-bottom-color: var(--hmf-primary500);
  --quick-view-slider-dots: var(--hmf-tertiary900);
  --quick-view-unlisted-price-text-transform: none;
  --quick-view-add-to-cart-button-color: var(--hmf-primary400);

  // STORE PICKUP
  --store-pickup-selected-store-color: var(--hmf-secondary800);

  // LINK COMPONENT
  --link-component-font-size: var(--body-font-size-s);
  --link-component-font-weight: 600;
  --link-component-letter-spacing: 0.5px;
  --link-component-line-height: 20px;

  --link-component-hover-focus-active-bottom-border: var(--hmf-secondary800);
  --link-component-static-bottom-border: var(--font-color-primary);
  --link-component-visited-bottom-border: var(--hmf-gray900);

  --link-component-alternate-font-color: var(--hmf-gray700);

  --link-component-inline-font-size: var(--body-font-size-s);
  --link-component-inline-line-height: 20px;

  --link-component-inline-active-visited-font-color: var(--hmf-gray700);
  --link-component-inline-hover-focus-font-color: var(--hmf-secondary800);

  // G3 SPECIFIC REACT BUTTON VALUES
  --button-active-bg-color: var(--hmf-primary600);
  --button-color: var(--hmf-accent50);
  --button-focus-outline: var(--hmf-primary600);
  --button-hover-bg-color: var(--hmf-primary600);
  --button-label-underline-color: var(--hmf-accent50);
  --button-static-bg-color: var(--hmf-secondary600);

  // G3 SPECIFIC - BUTTON COMPONENT - COMMON STYLES
  --button-font-family: var(--font-family-secondary);
  --button-font-size: 16px;
  --button-font-style: normal;
  --button-font-weight: bold;
  --button-height: 45px;
  --button-letter-spacing: 1.5px;
  --button-line-height: 16px;
  --button-text-transform: uppercase;
  --button-width: 100%;

  // G3 SPECIFIC - BUTTON COMPONENT - PRIMARY

  // PRIMARY STATIC
  --button-primary-static-bg-color: var(--hmf-secondary800);
  --button-primary-static-border: none;
  --button-primary-static-font-color: var(--color-white);
  --button-primary-static-radius: 0px;

  // PRIMARY HOVER
  --button-primary-hover-bg-color: var(--hmf-primary500);
  --button-primary-hover-border: none;
  --button-primary-hover-font-color: var(--color-white);
  --button-primary-hover-radius: 0px;

  // PRIMARY ACTIVE
  --button-primary-active-bg-color: var(--hmf-secondary900);
  --button-primary-active-border: none;
  --button-primary-active-font-color: var(--color-white);
  --button-primary-active-radius: 0px;

  // PRIMARY INACTIVE
  --button-primary-inactive-bg-color: var(--hmf-gray300);
  --button-primary-inactive-border: none;
  --button-primary-inactive-font-color: var(--hmf-gray600);
  --button-primary-inactive-radius: 0px;

  // PRIMARY FOCUSED
  --button-primary-focus-bg-color: var(--hmf-secondary800);
  --button-primary-focus-border: none;
  --button-primary-focus-font-color: var(--color-white);
  --button-primary-focus-outline: 1px dashed var(--hmf-secondary800);
  --button-primary-focus-radius: 0px;

  // G3 SPECIFIC - BUTTON COMPONENT - SECONDARY

  // SECONDARY STATIC
  --button-secondary-static-bg-color: var(--color-white);
  --button-secondary-static-border: 3px solid var(--hmf-secondary800);
  --button-secondary-static-font-color: var(--hmf-secondary800);
  --button-secondary-static-radius: 0px;

  // SECONDARY HOVER
  --button-secondary-hover-bg-color: var(--color-white);
  --button-secondary-hover-border: 1px solid var(--hmf-primary500);
  --button-secondary-hover-font-color: var(--hmf-primary500);
  --button-secondary-hover-radius: 0px;

  // SECONDARY ACTIVE
  --button-secondary-active-bg-color: var(--hmf-primary500);
  --button-secondary-active-border: 2px solid var(--hmf-primary500);
  --button-secondary-active-font-color: var(--color-white);
  --button-secondary-active-radius: 0px;

  // SECONDARY INACTIVE
  --button-secondary-inactive-bg-color: var(--color-white);
  --button-secondary-inactive-border: 2px solid var(--hmf-gray500);
  --button-secondary-inactive-font-color: var(--hmf-gray600);
  --button-secondary-inactive-radius: 0px;

  // SECONDARY FOCUSED
  --button-secondary-focus-bg-color: var(--color-white);
  --button-secondary-focus-border: 2px solid var(--hmf-secondary800);
  --button-secondary-focus-font-color: var(--hmf-secondary800);
  --button-secondary-focus-outline: 1px dashed var(--hmf-secondary800);
  --button-secondary-focus-radius: 0px;

  // G3 SPECIFIC - BUTTON COMPONENT - TERTIARY

  // TERTIARY STATIC
  --button-tertiary-static-bg-color: var(--color-white);
  --button-tertiary-static-border: none;
  --button-tertiary-static-font-color: var(--hmf-secondary800);
  --button-tertiary-static-radius: 0px;

  // TERTIARY HOVER
  --button-tertiary-hover-bg-color: var(--color-white);
  --button-tertiary-hover-border: none;
  --button-tertiary-hover-font-color: var(--hmf-secondary800);
  --button-tertiary-hover-label-underline: 1px solid var(--hmf-primary500);
  --button-tertiary-hover-radius: 0px;

  // TERTIARY ACTIVE
  --button-tertiary-active-bg-color: var(--color-white);
  --button-tertiary-active-border: none;
  --button-tertiary-active-font-color: var(--hmf-secondary800);
  --button-tertiary-active-label-underline: 2px solid var(--hmf-primary700);
  --button-tertiary-active-radius: 0px;

  // TERTIARY INACTIVE
  --button-tertiary-inactive-bg-color: var(--color-white);
  --button-tertiary-inactive-border: none;
  --button-tertiary-inactive-font-color: var(--hmf-gray600);
  --button-tertiary-inactive-radius: 0px;

  // TERTIARY FOCUSED
  --button-tertiary-focus-bg-color: var(--color-white);
  --button-tertiary-focus-border: none;
  --button-tertiary-focus-font-color: var(--hmf-secondary800);
  --button-tertiary-focus-outline: 1px dashed var(--hmf-secondary800);
  --button-tertiary-focus-radius: 0px;
}

.calia-react-footer-scope.hmf-sm-theme,
.calia-react-footer-scope.hmf-pl-theme {
  .sm-footer,
  .plf-footer {
    background-color: var(--footer-back-ground-color);
    color: var(--footer-links-heading-color);

    input {
      -webkit-appearance: none;
      border-radius: 0;
    }
  }

  .footer-container {
    max-width: 1600px;
    width: 100%;
  }

  .footer-site-link-container {
    list-style-type: none;

    @media (min-width: 992px) {
      flex-direction: row;
    }

    .footer-site-link {
      @media (min-width: 992px) {
        margin-left: var(--hmf-m);
        margin-right: var(--hmf-m);
      }
    }
  }
}

.calia-react-footer-scope {
  .footer-bottom-section {
    background: var(--hmf-background-base-subtle);

    .footer-bottom-details {
      width: 100%;
      max-width: 1600px;
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.dsg-header-2021 {
  background-color: var(--header-background-color);
  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  .mobile-nav-display {
    position: relative;
  }

  .mobile-nav-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    left: 0;
    overflow-y: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .dsg-search-wrapper {
    border-top: none;
    flex-grow: 1;
    @include homefield.media-breakpoint-up('md') {
      border-top: 1px solid var(--header-border-color);
    }
    @include homefield.media-breakpoint-up('xlg') {
      border-top: none;
      flex-grow: 1;
    }
  }

  .skip-link {
    background-color: var(--color-white);
    color: var(--color-black);
    height: 1px;
    left: -1000px;
    position: absolute;
    text-decoration: none;
    width: 1px;
    &:focus {
      display: block;
      height: unset;
      left: unset;
      width: fit-content;
      z-index: 200;
    }
    &:focus-visible {
      box-sizing: border-box;
      outline: 1px dashed var(--focus-color);
    }
  }
  .header2-pl-logo {
    width: 236px;
  }

  .header2-pl-full-logo {
    min-height: 32px;
    min-width: 280px;
    svg path {
      fill: var(--color-white);
    }
  }

  .header2-pl-icon {
    width: 50px;
    @media (max-width: 576px) {
      width: 30px;
    }
  }

  .header2-pl-mobile-logo {
    height: 40px;
    width: 120px;
    @media (max-width: 349px) {
      width: 86px;
    }
  }

  .header-gg-logo {
    min-width: 103px;
    min-height: 48px;
  }

  .dsg-search-wrapper-new-header {
    border-top: 1px solid var(--header-border-color);
    @include homefield.media-breakpoint-up('md') {
      border-top: none;
      flex-grow: 1;
    }
  }
  .hamburger-menu-new {
    color: var(--hmf-gray900);
    cursor: pointer;

    .hamburger-text {
      color: var(--hmf-gray900);
    }

    &.desktop {
      margin-left: var(--hmf-xxs);
      order: 2;
    }
    &:active {
      background: var(--hmf-fill-secondary-active);
      border-radius: var(--hmf-softer-radius);
    }
    &:hover {
      background-color: var(--hmf-fill-secondary-hover);
      border-radius: var(--hmf-softer-radius);
    }
    &:focus-visible {
      background-color: var(--hmf-fill-secondary-static);
      border: 1px dashed var(--hmf-border-focused);
      border-radius: var(--hmf-softer-radius);
      outline: none;
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-header-scope.hmf-g3-theme {
  .rc-dropdown-menu {
    list-style: none;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    text-align: center;
    width: 100%;
  }

  .category {
    &-list {
      list-style: none;
    }

    .category-title-block {
      border-bottom: 1px solid var(--hmf-border-base);
      overflow: auto;

      .category-title-link {
        .category-title {
          color: var(--font-color-primary);
        }
      }
    }
  }

  .rc-dropdown {
    &-links {
      max-width: 1600px;
      background-color: var(--hmf-background-color-base);
      left: 0;
      right: 0;
      text-align: left;
      width: 100%;
      z-index: 10;
    }
  }

  .rc-dropdown-title {
    > a {
      border-bottom: 3px solid transparent;
    }

    &:hover {
      > a {
        border-bottom: 3px solid var(--text-onhover-color);
        color: var(--text-onhover-color);
        text-decoration: none;
      }
    }

    &:focus {
      > a {
        border-bottom: 3px solid var(--text-onhover-color);
        color: var(--text-onhover-color);
        text-decoration: none;
      }
    }
  }

  .link {
    &-columns {
      width: 100%;
    }
  }

  .subcategory {
    > a {
      outline: none;
      outline-style: none;

      &:hover,
      &:focus {
        color: var(--text-onhover-color);
      }
    }

    &-container {
      list-style: none;
      min-width: 200px;
      max-width: 240px;
    }
  }
}

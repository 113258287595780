@use '~@dsg-tech/homefield-styles/src/lib/homefield.scss' as homefield;

/*! purgecss start ignore */
.toasty {
  background-color: var(--hmf-fill-base);
  border: 1px solid var(--hmf-border-base);
  border-radius: var(--hmf-xxxs);
  box-sizing: border-box;
  color: var(--hmf-label-base);
  font-size: 14px;
  left: 50%;
  min-height: 64px;
  position: fixed;
  top: var(--hmf-l);
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-in-out;
  width: 90%;
  z-index: 110;

  .toasty-href {
    width: 30%;

    svg path {
      fill: var(--hmf-label-base);
    }
  }

  .toasty-inline-message {
    white-space: pre-wrap;
    width: 100%;

    .homefield-textlink {
      display: inline;
      word-break: break-word;
    }
  }

  @include homefield.media-breakpoint-up('xsm') {
    width: 342px;
    top: calc(66px + 4.8%);
  }

  @include homefield.media-breakpoint-up('lg') {
    top: calc(48px + 4.8%);
    width: 656px;
  }

  .button-message {
    background: transparent;
    border: none;
    color: var(--hmf-label-base);
    cursor: pointer;
    text-decoration: underline;
  }

  .action-link {
    width: 90%;

    @include homefield.media-breakpoint-up('lg') {
      width: 100%;
    }
  }

  .toasty-cancelIcon {
    cursor: pointer;
  }
}
/*! purgecss end ignore */

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;
@use 'src/styles/_legacy-breakpoints.scss' as legacy-breakpoints;

.calia-react-header-scope.hmf-sm-theme {
  .sm-header {
    .nav-bar-container {
      background-color: var(--header-navbar-background-color);

      .nav-bar {
        max-width: 1600px;
        // z-index must be below slide in nav on mobile and above on desktop
        z-index: 3;

        @include homefield.media-breakpoint-up('m') {
          z-index: 11;
        }

        .donate-button {
          background-color: var(--color-green-base-600);
          text-decoration: none; // Need to override with css stylesheet as TextLink component prioritizes its own over inline styles passed
          color: var(--color-white);
          border: none;
        }
      }
    }

    .rc-sm-header {
      border: none;
      max-width: 1600px;

      .rc-subheader {
        border-top: none;

        .main-logo {
          flex-grow: 0; // MainLogo component overrides this to 1 and takes precedence, need to put style here to override
        }

        .rc-sm-logo-link {
          &:focus {
            border: 1px dashed var(--color-green-base-600);
          }

          img {
            width: 225px;
            height: 66px;
          }

          @include homefield.media-breakpoint-up('m') {
            img {
              height: 85px;
              width: 290px;
            }
          }
        }
      }
    }

    .header-menu-hamburger {
      box-sizing: content-box;
    }

    .header-menu-hamburger-btn {
      background-color: transparent;
      border: none;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
      }

      &:hover {
        background-color: transparent !important;
        border: none !important;
      }
    }
  }

  .responsive-header-mobile-nav-open {
    overflow: auto !important;
    @include homefield.media-breakpoint-up('m') {
      overflow: hidden !important;
    }
  }
}

@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

.calia-react-footer-scope {
  .footer-middle-section {
    background: var(--hmf-background-base-subtle);

    .grid {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 1600px;

      @include homefield.media-breakpoint-up('xlg') {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .dsg-react-footer-sign-up-success-msg {
    color: var(--hmf-scgreen);
  }
}

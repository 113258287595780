/*! purgecss start ignore */
@use '~@dsg-tech/homefield-styles/src/lib/homefield-functions.scss' as homefield;

:root {
  --vh: 100%;
}

.progressive-drawer-container {
  .hmf-modal-container {
    width: 100% !important;
    @include homefield.media-breakpoint-up('lg') {
      max-width: 330px !important;
      transition: max-width 0.2s ease-in-out !important;
      width: auto !important;
    }
    @include homefield.media-breakpoint-up('xlg') {
      max-width: 400px !important;
      transition: max-width 0.2s ease-in-out !important;
      width: auto !important;
    }
  }

  .progressive-drawer-content-container {
    width: 100%;
    .header-container {
      .header-logo-container {
        &:focus-visible {
          box-sizing: border-box;
          outline: 1px dashed var(--hmf-gray600);
        }

        .dsg-logo {
          height: 42px;
          width: 96px;
          @include homefield.media-breakpoint-up('md') {
            height: 48px;
            width: 108px;
          }
        }
      }
      .search-icon-container,
      .close-icon-container {
        cursor: pointer;
        &:hover {
          background-color: var(--hmf-gray100);
          border-radius: var(--hmf-softer-radius);
        }
        &:focus-visible {
          background-color: var(--hmf-gray100);
          border-radius: var(--hmf-softer-radius);
          box-sizing: border-box;
          outline: 1px dashed var(--hmf-gray600);
        }
      }
      .sayT-search-container {
        width: 100%;
        .sayT-search-bar {
          width: 100%;
          .sayT-button {
            background: var(--hmf-background-base-subtle);
            border: 1px solid var(--hmf-border-base);
            border-radius: 32px;
            height: 48px;
            width: 100%;
            .sayT-icon-text-container {
              width: 100%;
              svg {
                path {
                  fill: var(--hmf-label-base-subtle);
                }
              }
              .search-text {
                color: var(--hmf-label-base-subtle);
                font-size: 16px !important;
              }
            }
          }
        }
      }
    }
    .body-container {
      overflow-x: hidden;
    }
    .nav-body-container {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(var(--vh, 1vh) * 100 - 72px);
      @include homefield.media-breakpoint-up('lg') {
        min-width: 330px;
        width: 330px;
        height: calc(var(--vh, 1vh) * 100 - 132px);
      }
      @include homefield.media-breakpoint-up('xlg') {
        min-width: 400px;
        width: 400px;
      }
    }
    .l0-links-container,
    .l1-links-container,
    .l2-links-container {
      width: 100%;
      @include homefield.media-breakpoint-up('lg') {
        min-width: 330px;
        width: 330px;
      }
      @include homefield.media-breakpoint-up('xlg') {
        min-width: 400px;
        width: 400px;
      }
      .links-body {
        width: 100%;
      }
      .links-title {
        max-width: 200px;
        @include homefield.media-breakpoint-up('lg') {
          max-width: 400px;
          text-overflow: ellipsis;
        }
      }
      .shop-all {
        border-width: 1px;
        height: 32px;
        margin-left: var(--hmf-s);
        min-width: 100px;
        &:hover {
          background: linear-gradient(180deg, #fff 82%, rgba(255, 255, 255, 0.1) 100%);
          background-color: unset;
          border-width: 1px;
        }
      }
      a {
        color: var(--hmf-gray900);
        text-decoration: none;
      }
      .l0-link-content {
        @include homefield.media-breakpoint-up('xlg') {
          min-height: unset;
          width: 350px;
        }
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: var(--hmf-xxxs);
        min-height: 44px;
        &:hover:not(.l0-link-content-selected) {
          background-color: var(--hmf-gray100);
        }
        &:focus-visible {
          background-color: var(--hmf-gray100);
          box-sizing: border-box;
          outline: 1px dashed var(--hmf-gray600);
        }
        &-selected {
          @include homefield.media-breakpoint-up('lg') {
            background-color: var(--hmf-gray200);
          }
          &:focus-visible {
            background-color: var(--hmf-gray200);
            box-sizing: border-box;
            outline: 1px dashed var(--hmf-gray600);
          }
        }
      }
      .title-color {
        color: var(--hmf-gray900);
      }
      .sale-color {
        color: var(--hmf-red600);
      }
    }
    .l1-links-body-container,
    .l2-links-body-container {
      overflow-y: auto;
      height: calc(var(--vh, 1vh) * 100 - 146px);
      @include homefield.media-breakpoint-up('lg') {
        height: calc(var(--vh, 1vh) * 100 - 197px);
      }
    }
    .l1-links-container,
    .l2-links-container {
      animation: slide-in-L1-container-mobile 0.3s ease-out;
      @include homefield.media-breakpoint-up('lg') {
        animation: none;
      }
    }
  }
  @keyframes slide-in-L1-container-mobile {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
}

/*! purgecss end ignore */

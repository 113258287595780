.calia-react-footer-scope.hmf-sm-theme,
.calia-react-footer-scope.hmf-pl-theme .plf-footer {
  .footerSocialLinks-container {
    svg {
      height: 25px;
      width: 57px;

      path {
        fill: var(--hmf-gray50);
      }
    }

    .footer-facebook-icon {
      height: 33px;
      transform: translateY(3px);
    }

    a {
      display: inline-block;

      &:focus-visible {
        box-sizing: border-box;
        outline: 1px dashed var(--focus-color);
        outline-offset: 5px;
      }
    }
  }
}

.store-locator-sdd-container {
  background-color: var(--hmf-gray100);
  border: var(--hmf-gray100);
  border-radius: var(--hmf-softer-radius);
  height: 72px;
  width: 100%;
  &:hover {
    background: var(--hmf-gray200);
  }
  &:focus-visible {
    background-color: var(--hmf-gray100);
    box-sizing: border-box;
  }
}

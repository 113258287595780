/* Custom properties */
:root {
  --tooltip-text-color: var(--hmf-primary900);
  --tooltip-background-color: var(--hmf-background-base-subtle);
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
  --tooltip-border-color: var(--hmf-tertiary400);
}

.Tooltip-Wrapper {
  background: none;
  border: none;
}

.tooltip-content {
  white-space: normal;
}

/* Absolute positioning */
.Tooltip-Tip {
  background: var(--tooltip-background-color);
  border: 1px solid var(--tooltip-border-color);
  border-radius: 4px;
  color: var(--tooltip-text-color);
  left: 50%;
  max-width: 254px;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 100;

  .Tooltip-Tip-closeButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    right: 5px;
    top: 5px;

    &:focus {
      outline: 1px dashed var(--focus-color);
    }
  }
}

/* CSS border triangles */
.Tooltip-Tip::before {
  border: solid transparent;
  border-width: var(--tooltip-arrow-size);
  content: ' ';
  height: 0;
  left: 50%;
  margin-left: calc(var(--tooltip-arrow-size) * -1);
  pointer-events: none;
  position: absolute;
  width: 0;
}

.Tooltip-Tip::after {
  border: solid transparent;
  border-width: var(--tooltip-arrow-size);
  content: ' ';
  height: 0;
  left: 50%;
  margin-left: calc(var(--tooltip-arrow-size) * -1);
  pointer-events: none;
  position: absolute;
  width: 0;
}

/* Absolute positioning */
.Tooltip-Tip.top {
  bottom: calc(var(--tooltip-margin));
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  border-top-color: var(--tooltip-border-color);
  top: 100%;
}
/* CSS border triangles */
.Tooltip-Tip.top::after {
  border-top-color: var(--tooltip-background-color);
  top: 99%;
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  border-right-color: var(--tooltip-border-color);
  left: calc(var(--tooltip-arrow-size) * -1.2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::after {
  border-right-color: var(--tooltip-background-color);
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  top: calc(var(--tooltip-margin));
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  border-bottom-color: var(--tooltip-border-color);
  bottom: 100%;
}
/* CSS border triangles */
.Tooltip-Tip.bottom::after {
  border-bottom-color: var(--tooltip-background-color);
  bottom: 99%;
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  border-left-color: var(--tooltip-border-color);
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2.2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::after {
  border-left-color: var(--tooltip-background-color);
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

@use 'slick-carousel/slick';
@use 'slick-carousel/slickTheme';

body {
  margin: 0;

  &.ticker-drawer-open {
    overflow: hidden !important;
  }

  // both classes needed to handle scenario when search is open and navigation is open...
  // if search is then closed but navigation is still open, body should still not have scroll.
  &.navigation-open {
    overflow: hidden !important;
  }

  // search-results-open stops scroll when mobile navigation or desktop navigation are open
  &.search-results-open {
    overflow: hidden !important;
  }

  &.modal-open {
    overflow: hidden !important;
  }

  // full screen modal fix for iOS, prevents scroll and overflow
  &.slidein-open {
    overflow: hidden !important;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}

.dsg-header-2021,
.footerV2-container-2021,
.old-footer {
  img {
    border-style: none;
    vertical-align: middle;
  }

  svg:not(:root) {
    overflow: hidden;
    vertical-align: middle;
  }

  input,
  select,
  optgroup,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
}

.noPLP {
  background-color: red;
  height: 4000px;
}

.calia-react-footer-scope.hmf-pl-theme {
  .footerSocialLinks-container {
    text-align: center;
    svg {
      height: 40px;
      width: 40px;

      path {
        fill: var(--hmf-secondary100);
      }
    }
    &__text {
      color: var(--footer-social-links-color);
      span {
        font-weight: bold;
      }
    }

    a:focus-visible {
      box-sizing: border-box;
      outline: 1px dashed var(--focus-color);
    }
  }
}

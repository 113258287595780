@use '~@dsg-tech/homefield-styles/src/lib/homefield.scss' as homefield;

.calia-react-header-scope {
  .quickview-container {
    overflow: hidden !important;
  }

  @keyframes slideIn {
    0% {
      right: -40%;
    }
    100% {
      right: 0;
    }
  }

  .quickview {
    background-color: var(--hmf-background-base);
    float: right;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 0;
    width: 40%;
    z-index: 106;

    &.slide-in {
      animation-duration: 0.4s;
      animation-name: slideIn;
    }

    :focus {
      outline: 1px dashed var(--theme-focus-outline-color);
    }

    .close {
      border: none;
      background-color: var(--hmf-background-inverse);
      cursor: pointer;
      right: 0;
      top: 0;

      svg {
        path {
          fill: var(--hmf-fill-base-subtle);
        }
      }
    }
  }

  @include homefield.media-breakpoint-down('md') {
    .quickview {
      width: 100%;
    }
  }
}
